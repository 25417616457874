import dexieDB from "./dexie-cloud-data.js"

app.makeScrollable=()=>{
    const scrollableDiv = document.getElementById('triggerit');
    if(!scrollableDiv)return
    let isDragging = false;
    let startX, startY, scrollLeft, scrollTop;

    scrollableDiv.addEventListener('mousedown', (e) => {
        isDragging = true;
        startX = e.pageX - scrollableDiv.offsetLeft;
        startY = e.pageY - scrollableDiv.offsetTop;
        scrollLeft = scrollableDiv.scrollLeft;
        scrollTop = scrollableDiv.scrollTop;
        scrollableDiv.style.cursor = 'grabbing';
    });

    scrollableDiv.addEventListener('mouseleave', () => {
        isDragging = false;
        scrollableDiv.style.cursor = 'default';
    });

    scrollableDiv.addEventListener('mouseup', () => {
        isDragging = false;
        scrollableDiv.style.cursor = 'default';
    });

    scrollableDiv.addEventListener('mousemove', (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollableDiv.offsetLeft;
        const y = e.pageY - scrollableDiv.offsetTop;
        const walkX = (x - startX) * 2; // Adjust the multiplier for faster/slower scrolling
        const walkY = (y - startY) * 2;
        scrollableDiv.scrollLeft = scrollLeft - walkX;
        scrollableDiv.scrollTop = scrollTop - walkY;
    });
}

app.centerChildDiv=()=>{
    const childs=query('.w2ui-panel-content>.outer-container').get()
    if(childs.length!=1){
        console.log(DEBUG_ALL,'no contentPanel>treeview??')
        return
    }
    const child=childs[0]
    const parentRect = child.parentElement.getBoundingClientRect();
    const childRect = child.getBoundingClientRect();
    const offsetX = (childRect.width - parentRect.width) / 2;
    const offsetY = (childRect.height - parentRect.height) / 2;
    child.scrollLeft = child.scrollWidth/2-600
}

function observeDOMChanges(targetNode, callback, options = { childList: true, subtree: true }) {
    // Ensure the MutationObserver API is available
    if (!window.MutationObserver) {
      console.log(DEBUG_ALL,"MutationObserver is not supported in your browser.");
      return;
    }
  
    // Create a new MutationObserver instance
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        callback(mutation);
      }
    });
  
    // Start observing the target node for configured mutations
    observer.observe(targetNode, options);
  
    // Return the observer instance for potential further use (e.g., to disconnect later)
    return observer;
  }

const targetNode = document.getElementById('treediv');
const callback=async mutation=>{
    //console.log(DEBUG_ALL,'DOM change detected:', mutation);
    let found=false
    mutation.addedNodes.forEach(f=>{if(f.id=='triggerit')found=f;})//treediv
    if(found){
        observer.disconnect()//no need to watch during a time consuming build
        //console.log(DEBUG_ALL,'treediv node added to DOM:', mutation);
        await show(null,app.treeRoot)
        .then(()=>{
            observer.observe(document.body, { childList: true, subtree: true });
        })
    }
};

// Start observing Dom for changes. In pareticular looking for a new treediv structure to populate with a tree with root in app.treeRoot
//***const observer = observeDOMChanges(document.body, callback);

window.redrawTree=async()=>{
    let treeDiv=document.getElementById('treediv') 
    if(!treeDiv){
        let grids=query('#layout_layout_panel_main .w2ui-panel-content .w2ui-grid-box').get()
        if(grids.length){
            app.ownedGrid.records=await db.toArray(items=>items.filter(f=>f?.realmId!='rlm-public').map((m,i)=>{m.recid=i;return m}))
            app.ownedGrid.refresh()  
            return Promise.resolve({message:'treediv node not found'});
        }
    }else{
        treeDiv.innerHTML=''
        return await show(treeDiv, app.treeRoot);
    } 
}

const show=async (treeRootNode, treeRoot, indexOfChild)=>{
    if(!treeRootNode){
        treeRootNode=document.getElementById('treediv')
        if(!treeRootNode){return Promise.reject({message:'treediv node not found'});}
        treeRootNode.innerHTML=''
    }
    if(!treeRoot)return Promise.reject({message:'Invalid app.treeRoot'});
    if(app.histi==-1)app.history.splice(++app.histi,1,treeRoot);
    if(!isArr1InArr2(treeRoot,app.history[app.histi]))app.history.splice(++app.histi,0,treeRoot);
    app.buildTopToolbar()
    app.buildLeftToolbar('tree-view')
    return await Promise.all(treeRoot.map(async uid=>{
        app.childsCob=''
        await showTree(treeRootNode, uid,indexOfChild);
    })).then(()=>{
        console.log(DEBUG_ALL,'finished all showtrees')
        //attachTooltips() 
    })
}
app.show=show

const loadLoader=()=>{
    let loader=query('#loader')
    if(loader.length){
        loader.get(0).classList.add('loader')
        let exists=app.w2tooltip.get('loader')
        if(exists)
            //exists.remove()
            exists.unmount()
        app.w2tooltip.attach(//app.w2utils.extend({ "class": "w2ui-light" }),
            {
            html: `MaxDepth:${app.maxDepth}<br>${app.currentScale}`,
            name:'loader',
            anchor: loader.get(0).parentElement,
            autoShow: true
        })
    }
    return loader
}

const showTree=async(node, uid, indexOfChild)=>{
    let scalingWrapper=document.getElementById('scalingWrapper')  
    let currentScale=app.currentScale
    if(scalingWrapper&&currentScale){
        let currentScalingValue=scalingWrapper.style.transform
        scalingWrapper.style.transform=currentScale
    }
    let depth=0;
    app.duplicates=[]
    app.start = Date.now();
    //let sr=query('#scalingWrapper')
    //if(sr.length)sr.get(0).classList.add('busy')
    let loader=loadLoader()
    await getItem_recursive(node, uid, indexOfChild, depth, 0)
    .finally(f=>{
        let timeTaken = Date.now() - app.start;
        //sr=query('#scalingWrapper')
        //if(sr.length)sr.get(0).classList.remove('busy')
        if(loader.length)loader.get(0).classList.remove('loader')
        console.log(DEBUG_ALL,"Total time taken : " + timeTaken + " milliseconds");
        app.makeScrollable()
        app.centerChildDiv()
        let treeDiv=document.getElementById('treediv');
        if(treeDiv){
            if(app?.colourKey){
                treeDiv.classList.remove('cob')
                treeDiv.classList.add(app.colourKey)
            }else{
                treeDiv.classList.remove(app.colourKey)
            }
        }
        w2ui.layout.get('top').toolbar.render()
    })
};app.showTree=showTree

const isArr1InArr2=(arr1, arr2)=>{
    try{
      if (arr1.length !== arr2.length) return false;
      for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) return false;
      }
  }catch(e){
      console.log(DEBUG_ALL,e.message)
      return false
  }  
      return true;
  }

const back=()=>{
    if(app.histi>0)app.treeRoot=app.history[--app.histi];
    show(null, app.treeRoot).catch(err=>console.log(DEBUG_ALL,err.message))
    app.setURLSearchParam('uid',app.treeRoot)
}
app.back=back

const forward=()=>{
    if(app.histi+1>app.history.length-1)app.histi=app.history.length-1;
    else app.treeRoot=app.history[++app.histi];
    show(null, app.treeRoot).catch(err=>console.log(DEBUG_ALL,err.message))
    app.setURLSearchParam('uid',app.treeRoot)
}
app.forward=forward

app.getItem=(items, uid)=>{ //given All Items return best fit single item in order of: 1) The first non rlm-public, 2) the first rlm-public 3) created new item (not in persons)
    let item
    //items=items.filter(f=>!f?.notFound)
    if(app?.persons){
        items=[...items,...app.persons.filter(f=>f.uid==uid)]
    }
    if(!items.length){
        item={
            uid:uid,
            Name:'not found',
            Label:'Item not found',
            Parents:[],Children:[],Spouses:[],
            //actions:[{type:'simpleCmd1',cmd:'<button onclick="action()">MU tree</button>'}],
            notFound:true,
        }
    }
    let realmPublic=items.filter(f=>f.realmId=='rlm-public')
    if(realmPublic.length>1){
        console.log(`refine choice of realmPublic items??? ${uid}`)
        realmPublic.forEach(item=>console.log(item.id))
        item = realmPublic[0]//gaan maar aan
    }else if(realmPublic.length==1)
        item = realmPublic[0];//take the first item
    //if(item.uid=='19e87d05-6534-4167-8cb4-86f467a1e4f2')    
    let nonPublic=items.filter(f=>f.realmId!='rlm-public')
    if(nonPublic.length>1){
        console.log(`refine choice of nonPublic items??? uid:'${uid}'`);
        nonPublic.forEach((item)=>console.log(`id:'${item.id}'`));
        let nonPublicNotNotFound=nonPublic.filter(f=>!f?.notFound)
        if(nonPublicNotNotFound.length>0){
            if(nonPublicNotNotFound>1){
                console.log(`There are more than 1 nonPublicNotNotFound${uid}`)
            }
            item=nonPublicNotNotFound[0]//there is no id for this record
        }else{
            item = nonPublic[0]
        }
        //let onlyValid=items.filter(f=>!f?.notFound)
    }else if(nonPublic.length==1)
        item = nonPublic[0]//take the first non rlm-public
    if(!('Parents' in item)&&!(item?.Parents?.length))item.Parents=[]
    if(!('Children' in item)&&!(item?.Children?.length))item.Children=[]
    return item
}

const getItem_recursive=async(node, uid, indexOfChild, depth, depthOveride)=>{
    return await app.dexieDB.persons.where('uid').equals(uid).toArray(async items=>{//We're drawing a node with an associated uid, so get info from database for this uid (can be many items that have same uid)
        let item = app.getItem(items, uid)
        if(!item){console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!')}
        if(!depth){
            app.childsAge=item.Born
        }else{
            app.childsCob=node.firstElementChild.dataset?.cob //reference to the childs span
        }
        if((!app.maxDepth||depth<(app.maxDepth+depthOveride))&&item?.Parents&&(!item?.truncate||node.id=='treediv')){
            item.depth=depth
            node.dataset.depth=depth
            //for progress display only
                let loaderCounter=query('#loader-counter')
                if(loaderCounter.length)loaderCounter.get(0).dataset.depth='depth:'+depth
            let rSpan=insertNode(node, item, indexOfChild);
            node=rSpan.parentElement
            depth++
            if(!rSpan.classList.contains('dup-truncated')){
                return await Promise.all(item.Parents.map(async uid=>{
                    return await getItem_recursive(node, uid, undefined, depth, depthOveride)
                }))
            }else{
                node.appendChild(document.createElement('span'))
            }
        }else{
            //the reason we're here is because of no more Parents, depth, duplicate or truncate
            if(!app.query('DS',node).length)addTerminatingHasMoreParents(node.querySelector('span'));
            if(item?.truncate){
                query(':scope>span',node).each(span=>{
                    //console.log(4,span.textContent+' is hidden')
                    let truncateSpan
                    span.appendChild(truncateSpan=document.createElement('SPAN'))
                    truncateSpan.classList.add('truncated')  
                    truncateSpan.title="There are hidden elements below"
                    truncateSpan.textContent="Truncated"
                })
            }
        }
        return item
    })
    .catch(err=>console.log(DEBUG_ALL,err))
};app.getItem_recursive=getItem_recursive

const insertNode=(node, item, indexOfChild)=>{
    /***************************************************** */
    let pChild=app.query(':scope>span',node)
    if(pChild.length){
        let pItem=pChild[0].item
        if(!('Children' in item))item.Children=[]
        if(item.Children.indexOf(pItem.uid)==-1){
            item.Children.push(pItem.uid)
            app.updateUid(item)
        }
    }
    /***************************************************** */
    let ul = node.querySelector('ul');
    if (!ul) {
        ul = document.createElement('ul');
        node.appendChild(ul);
    }
    let li = document.createElement('li');
    //li.classList.add(rec.uid);
    let span
    span=app.buildContentSpan(node, item)
    //(span=document.createElement('span'),span.textContent=item.Name,span.dataset.uid=item.uid,span.classList.add('tf-nc'),span.item=item)
    if(span){
        li.appendChild(span)
        //attachTooltip(span)
    }
    //if given, append at indexOfChild
    if(typeof indexOfChild==='number'){
        //parentElement.insertBefore(newElement, parentElement.childNodes[2]);
        ul.insertBefore(li,ul.childNodes[indexOfChild])
    }else{
        ul.appendChild(li);
    }
    return span   
}

app.buildContentSpan=(node, item)=>{//node is ul
    let alreadyInDom=false, duplicateCount=0
    app.query('span.tf-nc[data-uid="'+item.uid+'"]').each(treeSpan=>{
            duplicateCount++
            //dealWithDuplicates(node, item, treeSpan);
            treeSpan.classList.add('duplicate')
            alreadyInDom=true
    })
    let span = document.createElement('span');
    span.classList.add('tf-nc');
    span.dataset.uid=item.uid
    if(item){
        span.textContent=item?.Name?item.Name:'No Name';
        span.item=item
        adornSpan(span, item, duplicateCount)
        if(alreadyInDom){//mod the duplicate
            //console.log(DEBUG_ALL,`Duplicate:${duplicateCount} ${item.uid} ${item.Name}`)
            app.duplicates.push({a:`Duplicate:${duplicateCount} ${item.uid} ${item.Name}`})
            //item.Parents=[]
            //span.textContent='Duplicate: '+span.textContent.replace(/^Duplicate:\s/,'')
            //span.textContent='Duplicate: '+span.item.Name.replace(/^Duplicate:\s/,'')
            span.classList.add('duplicate','dup-truncated')
        }
    }else{
        span.textContent='Could not read item';
    } 
    return span
}

const dealWithDuplicates=(node, item, treeSpan)=>{//mod the original
    console.log(DEBUG_ALL,'Duplicate:'+item.id+' '+item.Name)
    //treeSpan.textContent='ODuplicate: '+treeSpan.item.Name.replace(/^Duplicate:\s/,'')
    //item.id=app.generateUUID()
    treeSpan.classList.add('duplicate') 
}

const adornSpan=(span, item, duplicateCount)=>{
    addToolTipIfNone(span, duplicateCount) //add tooltip
    /*
    if(item?.truncate){//mark the child of this hidden parent
        let truncateSpan
        let childOf=query(':scope>span',span.parentElement.parentElement.parentElement)
        if(childOf.length){
            childOf[0].appendChild(truncateSpan=document.createElement('SPAN'))
            truncateSpan.classList.add('truncated')
        }
    }*/
    if(!app.showHide&&item.depth){
        let showHide
        span.appendChild(showHide=document.createElement('i'))
        showHide.textContent='×'
        showHide.classList.add('x')

    }

    //***if(app.showDepth){
        //***let depthSpan
        //***span.appendChild(depthSpan=document.createElement('SPAN'))
        //***depthSpan.classList.add('depthSpan')
        //switch(dispFlag){
        //case '':
        //depthSpan.textContent=item.depth
        //}else{
        //break;case '':
            //depthSpan.textContent=''+(app.childsAge-item.Born)
            //***depthSpan.textContent=''+item.id
            //app.childsAge=item.Born
        //}
    //***}

if(app.checkChildrenCount){
    db.toArray(items=>{
        let Children=items.filter(f=>f.Parents.indexOf(item.uid)>-1);
        if(Children.length){
            let cs=document.createElement('CS')
            cs.classList.add('nav-children')
            cs.title="show children"
            let uniqueChildrenLength=new Set(Children.map(m=>m.uid)).size
            //cs.textContent=''+(uniqueChildrenLength)
            cs.textContent=''+(item.Children.length)
            if(item?.Children&&(item.Children?.length!=Children?.length)){
                console.log(DEBUG_ALL,'Children<>Parent for:'+item.uid)
                cs.classList.add('error')
                item.linkedChildren=Children

                let csp=document.createElement('CSP')
                csp.classList.add('nav-child-check')
                csp.title="show children"
                //csp.textContent=''+(item.Children.length)
                csp.textContent=''+(uniqueChildrenLength)

                span.appendChild(csp)
            }
            span.appendChild(cs)
        }
    })
}else{ 
    if(item?.Children&&item.Children?.length){
        let cs=document.createElement('CS')
        cs.classList.add('nav-children')
        cs.title="show children"
        cs.textContent=''+(item?.Children?.length)
        span.appendChild(cs)
    }
}
    //adorn urls
    if(item?.Label){
        item.urls=getAllUrls(item.Label);
    }else{
        //console.log(DEBUG_ALL,'no Label for: '+ item.uid);
    }
    //let urlCount=JSON.parse(item.urls??"[]").length
    //let urlCount=item.urls?.length??0;
    if(item?.urls)item.urls.forEach((url,i)=>{
        let urlSelector = document.createElement('us');
        urlSelector.dataset.url=url
        urlSelector.dataset.uid=item.uid//+'_'+i
        urlSelector.classList.add('url');
        let domain = (new URL(url));
        //urlSelector.textContent ? urlSelector.textContent = urlCount : urlSelector.innerText = urlCount;
        let host=domain.hostname
        host=host.replace('www.','').replace('.com','')
        urlSelector.textContent ? urlSelector.textContent = host : urlSelector.innerText = host;
        urlSelector.title='open url'
        urlSelector.style.top=(i*1.2+'em')
        
        span.appendChild(urlSelector);
    })

    span.dataset.cob=getCountyOfBirth(item)//adorn cob

    if(item?.owner)span.dataset.owner=item.owner;
    if(item?.realmId)span.dataset.realm=item.realmId;
    if(item?.sex)span.dataset.sex=item.sex;
    if(item?.AncestorOf){
        let exists
        exists=item.AncestorOf.indexOf(app.treeOwner)
        if(exists>-1)
            span.dataset.descendant=true;
    }

    //adornAction(span, item)//adorn actions is disabled
};app.adornSpan=adornSpan

const addToolTipIfNone=(span, duplicateCount)=>{
    if(!span?.item)return;
    let uid=span.item?.uid
    if(!uid)return//and not duplicate??
    let exists=app.w2tooltip.get(uid)
    if(exists)exists.remove()
    //if(exists)return//commented out because we must replace tooltip in case it's different. It's the same name of uid
    let processedLabel=(span?.item?.Label?app.processLabel(span.item.Label):'')
    app.w2tooltip.attach(//app.w2utils.extend({ "class": "w2ui-light" }),
        {
        html: removeAllUrls(processedLabel),//if already done
        //html: (span?.item?.Label?span.item.Label:''),
        name:uid+'_'+duplicateCount,
        anchor: span,
        autoShow: true
    })
};app.addToolTipIfNone=addToolTipIfNone

const addTerminatingHasMoreParents=(span)=>{
    //adorn count of parents
    if(!span){
        console.log(DEBUG_ALL,'addTerminatingHasMoreParents has no valid span to work with');
        return
    }

    let ds=document.createElement('DS')
    ds.classList.add('ds','nav-parents')
    //let icon
    //ds.appendChild(icon=document.createElement('I'))
    //icon.classList.add('fa-solid', 'fa-circle-chevron-down')
    //icon.style.color='black'
    //if(item?.truncate){
    //    ds.style.color='red important!'
    //}
    let item=span?.item//span.item is typically child of parentItem
    if(!item){console.log(DEBUG_ALL,'no associated item for span',span.id);return}
    let numberOfParents=item?.Parents?.length
    //if(item?.Parents)ds.textContent=''+(item?.Parents?.length)
    if(numberOfParents){
        ds.classList.add('more')
        ds.title="expand parents"
    }else{
        ds.title="no more parents in database"
    }
    span.appendChild(ds)
    }
app.addTerminatingHasMoreParents=addTerminatingHasMoreParents

const getAllUrls=(str)=>{
    if(!str){
        //console.log(`getAllUrls not Label`)
        str=''
    }
    if(typeof str!='string'){
        console.log(`getAllUrls Label not a string`)
        str=''
    }
    const urlRegex = /(https?:\/\/[^\s<]+)/gim;
    const urls = str.match(urlRegex);
    return urls
};app.getAllUrls=getAllUrls

const removeAllUrls=(str)=>{
    if(!str){
        //console.log(`removeAllUrls not Label`)
        str=''
    }
    if(typeof str!='string'){
        console.log(`removeAllUrls Label not a string`)
        str=''
    }
    const urlRegex = /(https?:\/\/[^\s]+)/gim;
    const urls = str.replace(urlRegex,"");
    return urls
};app.removeAllUrls=removeAllUrls

const adornAction=(span, item)=>{
    if(item?.actions){
try{
        item.actions
        //console.log(DEBUG_ALL,'adorn action')
        let actionDiv=document.createElement('DIV')
        actionDiv.classList.add('action-div')
        item.actions.forEach(action=>{
            switch(action.type){
                case 'simpleCmd1':
                    //actionDiv.textContent=action.cmd
                    //actionDiv.style.width='250px'
                    //actionDiv.style.height='50px'
                    buildAction(actionDiv,action.cmd)
                    break;
            }
        })      
        //actionDiv.textContent='action'
        span.appendChild(actionDiv)
}catch(err){console.log(DEBUG_ALL,err)}
    }
}

const buildAction=(elem, html)=>{
    elem.innerHTML=html;
    elem.querySelector('button').action=()=>{
        app.treeRoot=['19e87d05-6534-4167-8cb4-86f467a1e4f2']
        app.setURLSearchParam('uid',app.treeRoot[0])
        //app.w2ui['layout'].html('main',app.placeTreeDiv().outerHTML)
        app.replaceTree()
    }
}

const attachTooltips=()=>{
    hideAllTooltips()
    app.query('span.tf-nc').each((span,i)=>{//.on('click', event => {
        let processedLabel=(span?.item?.Label?app.processLabel(span.item.Label):'');
        app.w2tooltip.attach({//app.w2utils.extend({ "class": "w2ui-light" }),
            html: removeAllUrls(processedLabel),//If not already done
            //html: (span?.item?.Label?span.item.Label:''),
            name:span.item.uid,
            anchor: span,
            autoShow: true
        })
    })
}

function hideAllTooltips(){
    app.w2tooltip.get().forEach(f=>{
try{
        let tt=app.w2tooltip.get(f)
        if(tt && tt?.displayed)tt.hide()
}catch(err){}
})}

const getCountyOfBirth=(item)=>{
    
    //m=[...r.Label.matchAll(/(born|birthplace|birth)([:\s])([^\n]*)(?:\n|http|occupation|died|death|buried|census|married|1841:|1851:|1861:|1871:|$)/igm)]
    //m=[...r.Label.matchAll(/(?:Born:|Birthplace:|Birth:)(.*?)[\s](?:http|occupation|died|death|buried|census|married|1841:|1851:|1861:|1871:|$)/igm)];
    let country=''
    let stringToTest = item?.Label
    if(!item.Label)return app.childsCob;

    let birthplace='';
    try{
    const match = stringToTest.match(/(?:Born|Birthplace|Birth):\s*(.*?)(?=\s*(?:http|occupation|died|death|buried|census|married|1841:|1851:|1861:|1871:|story:|$))/igm);
    
    if (match) {
        birthplace = match[0].trim();
        //console.log(DEBUG_ALL,birthplace);
    } else {
        //console.log(DEBUG_ALL,"Birthplace not found.");
    }
}catch(error){console.log(DEBUG_ALL,'can\'t match for country of birth')}

    
    if(birthplace){
        if(birthplace.length){
        //if(m.length>1)country='Gray';
        let fc;
        fc=birthplace.match(/South Africa/igm);if(fc)country='LightBlue'
        fc=birthplace.match(/Suid Afrika/igm);if(fc)country='LightBlue'

        fc=birthplace.match(/London/igm);if(fc)country='Blue'
        fc=birthplace.match(/England/igm);if(fc)country='Blue'
        fc=birthplace.match(/\sUK/gm);if(fc)country='Blue'
       
        fc=birthplace.match(/Netherlands/igm);if(fc)country='Orange'
        fc=birthplace.match(/Nederland/igm);if(fc)country='Orange'
        fc=birthplace.match(/Nederlands/igm);if(fc)country='Orange'
        fc=birthplace.match(/Holland/igm);if(fc)country='Orange'

        fc=birthplace.match(/Germany/igm);if(fc)country='Red'
        fc=birthplace.match(/Deutschland/igm);if(fc)country='Red'
        fc=birthplace.match(/Duitsland/igm);if(fc)country='Red'
        fc=birthplace.match(/Holy Roman Empire/igm);if(fc)country='Red'
        fc=birthplace.match(/Prussia/igm);if(fc)country='Red'
        fc=birthplace.match(/Switzerland/igm);if(fc)country='Red'
        fc=birthplace.match(/Austria/igm);if(fc)country='Red'
        

        fc=birthplace.match(/Belgium/igm);if(fc)country='Purple'
        fc=birthplace.match(/Belgie/igm);if(fc)country='Purple'
        fc=birthplace.match(/België/igm);if(fc)country='Purple'

        fc=birthplace.match(/France/igm);if(fc)country='Green'
        fc=birthplace.match(/Europe/igm);if(fc)country='Green'

        fc=birthplace.match(/Italy/igm);if(fc)country='rgb(250, 182, 233)'//'#C11C39'

        fc=birthplace.match(/Sweden/igm);if(fc)country='rgb(117, 255, 209)'
        fc=birthplace.match(/Swedish/igm);if(fc)country='rgb(117, 255, 209)'
        fc=birthplace.match(/Finland/igm);if(fc)country='rgb(117, 255, 199)'
        fc=birthplace.match(/Finnish/igm);if(fc)country='rgb(117, 255, 199)'
        fc=birthplace.match(/Norway/igm);if(fc)country='rgb(117, 255, 240)'
        fc=birthplace.match(/Norwegian/igm);if(fc)country='rgb(117, 255, 240)'
        

        fc=birthplace.match(/East London/igm);if(fc)country='LightBlue'
        fc=birthplace.match(/Somerset East/igm);if(fc)country='LightBlue'
        fc=birthplace.match(/Kaapstad/igm);if(fc)country='LightBlue'
        fc=birthplace.match(/Cape/igm);if(fc)country='LightBlue'   
        fc=birthplace.match(/Caap/igm);if(fc)country='LightBlue'
        fc=birthplace.match(/Cape Town/igm);if(fc)country='LightBlue'


        fc=birthplace.match(/Damfontein/igm);if(fc)country='Cyan'

        fc=birthplace.match(/Poland/igm);if(fc)country='Magenta'
        fc=birthplace.match(/Czech Republic/igm);if(fc)country='Magenta'

        fc=birthplace.match(/Timor/igm);if(fc)country='LightYellow'
        
        fc=birthplace.match(/Indonesia/igm);if(fc){country='LightYellow';}
        fc=birthplace.match(/India/igm);if(fc){country='LightYellow';}
        fc=birthplace.match(/Batavia/igm);if(fc){country='LightYellow';}//Dutch East Indies 

        fc=birthplace.match(/Central African Republic/igm);if(fc)country='Brown'
        fc=birthplace.match(/Guinea/igm);if(fc)country='Brown'
        fc=birthplace.match(/Madagascar/igm);if(fc)country='Brown'
        fc=birthplace.match(/Angola/igm);if(fc)country='Brown'

        }else{         
        }
    }else{
        //console.log(DEBUG_ALL,'!m-->'+item.Label);//Can't identify this birthplace
    }
    if(!country)if(typeof app.childsCob=='string')country=app.childsCob
    return country
}

function insertLineBreaks(text) {
    // Regular expression to match the first of, space, semicolon or full stop (which ever comes first) after every 40 characters
    //var regex =  /.{1,60}(?=\s|[.;]|$)/g;    ///.{1,40}[\s\.;]/g;
    var regex = /.{1,60}(\s|[.;]|$)/g;
    // Replace the matched chars with \n
    return text.replaceAll(regex, function(match) {
        return match + '<br>';
    });
}

app.processLabel=(processedLabel)=>{
    let urls=getAllUrls(processedLabel)
    processedLabel=removeAllUrls(processedLabel);//remove urls for display
    processedLabel=processedLabel.replaceAll(/<br>/gmi,' ');//remove <br>
    processedLabel=processedLabel.replaceAll(/ « less /gmi,' ');//
    processedLabel=processedLabel.replaceAll(/ « more /gmi,' ');//
    processedLabel=processedLabel.replaceAll(/[\n\s\t]/gmi,' ');//remove spaces (multi) and \n
    processedLabel=processedLabel.replaceAll(/[\s]{2,}/gmi,' ');//remove mmulti-spaces
    processedLabel=processedLabel.replaceAll('"','').replaceAll("'",'').replaceAll("`",'')
    let labelParts=processedLabel.split(/(?=1831:)|(?=1841:)|(?=1851:)|(?=1861:)|(?=1871:)|(?=1881:)|(?=1891:)|(?=2001:)|(?=2011:)|(?=2021:)|(?=Source:)|(?=Born:)|(?=Died:)|(?=Married:)|(?=Story:)|(?=Aka:)/igm)
    if(labelParts?.length){
        labelParts = labelParts.map(part=>insertLineBreaks(part))
    }else{}
return labelParts.join('')+(' ',urls?.length?urls.join('<br>'):'')
}

app.clearDB=()=>{
    app.dexieDB.delete({disableAutoOpen: false})
}

app.logout=()=>{
    app.dexieDB.cloud.logout().then(t=>{
        console.log(DEBUG_ALL,'Logged out')
        clearURLSearchParams()
        app.dexieDB.cloud.sync().then(t=>
            app.replaceTree(['00000000-0000-0000-0000-000000000000']))
})}

app.login=async (email)=>{
if(!email)email=document.getElementById('userInfoEmail').value
return app.dexieDB.cloud.login({email})
.catch(err=>{
    console.log(DEBUG_ALL,'Error with login:'+err.message)
    //debugger
})
.then(t=>{
    console.log(DEBUG_ALL,'Logged in')
    //let loggedInUser=document.getElementById("logged-in-user")
    //if(loggedInUser){
    //    loggedInUser.value=app.dexieDB.cloud.currentUserId
    //}else{

    //}
    let treeView=document.getElementById('triggerit')
    if(treeView)treeView.remove()
    app.dexieDB.cloud.sync()
    //debugger
    });}

//https://stackoverflow.com/questions/5467129/sort-javascript-object-by-key
const orderKeys=(unordered)=>{
    return Object.keys(unordered).sort((a,b)=>{
        if (a === 'uid') return -1; // 'uid' should come first
        if (b === 'uid') return 1;
        if (a === 'Name') return -1; // 'Name' should come next
        if (b === 'Name') return 1;
        if (a === 'Born') return -1; // 'Born' should come next
        if (b === 'Born') return 1;
        if (a === 'Label') return -1; // 'Label' should come next
        if (b === 'Label') return 1;
        if (a === 'Parents') return -1; // 'Parents' should come next
        if (b === 'Parents') return 1;
        if (a === 'Children') return -1; // 'Children' should come next
        if (b === 'Children') return 1;
        if (a === 'Spouses') return -1; // 'Spouses' should come next
        if (b === 'Spouses') return 1;
        if (a === 'Others') return -1; // 'Others' should come next
        if (b === 'Others') return 1;
        return a.localeCompare(b); // Default alphabetical sort for other keys
}).reduce(
    (obj, key) => { 
      obj[key] = unordered[key]; 
      return obj;
    }, 
    {}
  );
}

//("[\d]{1,4}":\{"uid":")
app.downloadArchive=async(realm, justShow)=>{
    console.log(DEBUG_ALL,'downloadArchive');//debugger
    if(typeof realm!='string')realm='rlm-public';//The public data
    if(realm=='unauthorized')realm='rlm-public';//The public data
    let realmData={data:{[realm]:{'persons':{}}}}
    return await app.dexieDB.persons.toArray()
    .then(items=>{
        let regex
        //items=items.filter(f=>f.realmId==realm)
        items.forEach(item=>{
            let tempId=item.id; delete item.id;
            //if(item?.Name)item.Name=item.Name.replaceAll('\\n','\n').replaceAll('\n','\\n');
            //if(item?.Label)item.Label=item.Label.replaceAll('\\n','\n').replaceAll('\n','\\n');
            //if(item?.Label)item.Label=item.Label.replaceAll('\n',' ').replaceAll(/\s{2}/igm,' ');
            if(item?.Label){
                item.Label=app.processLabel(item.Label);
                item.Label=item.Label.replaceAll(/<br>/igm,' ')
                item.Label=item.Label.replaceAll(/\n/igm,' ')
                item.Label=item.Label.replaceAll(/nBorn/igm,'Born')
                item.Label=item.Label.replaceAll(/« less/igm,' ')
                item.Label=item.Label.replaceAll(/\\/igm,' ')
                item.Label=item.Label.replaceAll(/[\s]{2}/igm,' ')
            }
            if(item?.Born){
                regex=new RegExp(''+item.Born,'gim')
                if(regex.exec(item.Name)){
                    delete item.Born
                }else{
                    console.log(`unmatched Born ${item.Name} ${item.Born}`)
                }
            }else{
                if('Born' in item)delete item.Born
            }
            if(item?.Died){
                regex=new RegExp(''+item?.Died,'gim')
                if(regex.exec(item.Name)){
                    delete item.Died
                }else{
                    console.log(`unmatched Died ${item.Name} ${item.Died}`)
                }
            }else{
                if('Died' in item)delete item.Died 
            }
            if('age' in item)delete item.age
            if('hidden' in item)delete item.hidden
            if('expand' in item)delete item.expand
            if('info' in item)delete item.info;
            if('urls' in item){
                if(item.urls?.length){
                    item.urls=item.urls.filter(url=>{
                        //url=url.replace(/<br>.*$/, "")
                        url=url.match(/(https?:\/\/[^\s<]+)/gim)[0]
                        url=url.replaceAll('?','\\?')
                        regex=new RegExp(url,'gim')
                        if(regex.exec(item.Label)){
                            return false
                        }else{
                            console.log(`unmatched url ${item.Name} ${item.Label} ${JSON.stringify(item.urls)}`)
                            return true
                        }
                    })
                    if(!item.urls.length) delete item.urls
                }else{
                    delete item.urls
                }
            }
            if('owner' in item)delete item.owner;
            if('realmId' in item)delete item.realmId;
            if('actions' in item)delete item.actions;
            let o=orderKeys(item)
            if(app?.setToNull && tempId>999){
                realmData.data[realm]['persons'][tempId]=null;
            }else{
                realmData.data[realm]['persons'][tempId]=o;
            }
            
        
        })     
        
        if(justShow){ 
            let jsonString=JSON.stringify(realmData)   
            console.log(DEBUG_ALL,jsonString)
        }else{
            var file = new File([JSON.stringify(realmData)], realm+".json", {type: "text/plain;charset=utf-8"});
            window.saveAs(file)
        }
        return realmData
    })
}

app.uploadArchive=async()=>{
    
}

//https://www.sitepoint.com/get-url-parameters-with-javascript/
app.getAllUrlParams=(url)=>{

    // get query string from url (optional) or window
    var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

// we'll store the parameters here
    var obj = {};

// if query string exists
if (queryString) {

  // stuff after # is not part of query string, so get rid of it
  queryString = queryString.split('#')[0];

  // split our query string into its component parts
  var arr = queryString.split('&');

  for (var i = 0; i < arr.length; i++) {
    // separate the keys and the values
    var a = arr[i].split('=');

    // set parameter name and value (use 'true' if empty)
    var paramName = a[0];
    var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

    // (optional) keep case consistent
    paramName = paramName.toLowerCase();
    if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

    // if the paramName ends with square brackets, e.g. colors[] or colors[2]
    if (paramName.match(/\[(\d+)?\]$/)) {

      // create key if it doesn't exist
      var key = paramName.replace(/\[(\d+)?\]/, '');
      if (!obj[key]) obj[key] = [];

      // if it's an indexed array e.g. colors[2]
      if (paramName.match(/\[\d+\]$/)) {
        // get the index value and add the entry at the appropriate position
        var index = /\[(\d+)\]/.exec(paramName)[1];
        obj[key][index] = paramValue;
      } else {
        // otherwise add the value to the end of the array
        obj[key].push(paramValue);
      }
    } else {
      // we're dealing with a string
      if (!obj[paramName]) {
        // if it doesn't exist, create property
        obj[paramName] = paramValue;
      } else if (obj[paramName] && typeof obj[paramName] === 'string'){
        // if property does exist and it's a string, convert it to an array
        obj[paramName] = [obj[paramName]];
        obj[paramName].push(paramValue);
      } else {
        // otherwise add the property
        obj[paramName].push(paramValue);
      }
    }
  }
}

return obj;
}

app.setURLSearchParam=(key, value)=>{//const forward=()=>{
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({ path: url.href }, '', url.href);
}
const clearURLSearchParams = () => {
    const url = new URL(window.location.href);
    url.search = ''; // Clears all search parameters
    window.history.pushState({}, '', url.pathname); // Keeps the base URL without search params
  };
  