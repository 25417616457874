import { w2layout, w2sidebar, w2grid, w2ui, w2popup, w2tooltip, 
    query, 
    w2toolbar, w2utils, w2form, w2field, w2menu, 
    w2alert, w2confirm, w2prompt} from 
//'../treeflexnw/public/assets/js/w2ui-2.0.es6.min.js'
//'../treeflexnw/public/assets/js/w2ui.es6_mumod.js'//look for mumod
'../treeflexnw/public/assets/js/w2ui.-3.es6.min.js'
//'../treeflexnw/public/assets/js/w2ui-2.0.es6.min.js'//this is an older version

//import $ from '../treeflexnw/public/assets/js/mquery.js'
var app={
    treeRoot:['00000000-0000-0000-0000-000000000000'],
    treeOwner:'19e87d05-6534-4167-8cb4-86f467a1e4f2',
    childsCob:"",
    childsEra:{},
    navOrEdit:false,
    debugLevel:0x1,//console debug reporting level 0=none;bit 1=Error;bit 2=Info; bit 3=Function entry
    hideDuplicates:true,
    showDepth:true,
    search:"",
    history:[],
    histi:-1,
    currentScale:"scale(1)",
    maxDepth:4,
    onlyAncestors:false, //flag for only showing those children that are ancestors of the given 'root ancestor'
    checkChildrenCount:false,
    unlock:false,
    colourKey:'source',//'cob or 'source' or 'sex' or 'descendant'
}

app.w2tooltip=w2tooltip
app.w2layout=w2layout
app.query=query
app.w2utils=w2utils
app.w2ui=w2ui
app.w2popup=w2popup
app.w2sidebar=w2sidebar
app.w2grid=w2grid
app.w2toolbar=w2toolbar
app.w2menu=w2menu
app.w2form=w2form
app.w2alert=w2alert
app.w2confirm=w2confirm
app.w2prompt=w2prompt

window.app=app
window.query=query
window.w2ui=w2ui

window.DEBUG_ERROR = 1;  // bit 1
window.DEBUG_INFO = 2;   // bit 2
window.DEBUG_ALL = 4; // bit 3
const oldConsole = {
    log: console.log.bind(console),
    info: console.info.bind(console),
    error: console.error.bind(console)
};
if(false)console.log=(...args)=>{
    let line=(new Error()).stack.split('\n')[2]//.match(/:(\d+):\d+/)[0]
    line=line.match(/(https?:\/\/[^\s<]+)/gim)
    oldConsole.log(line?.length?line[0]:'Error')
    let level=args.shift()
    /*if (level === window.DEBUG_ERROR && (app.debugLevel & window.DEBUG_ERROR)) {
        oldConsole.error(...args);
    } else if (level === window.DEBUG_INFO && (app.debugLevel & window.DEBUG_INFO)) {
        oldConsole.info(...args);
    } else if (level === window.DEBUG_ALL && (app.debugLevel & window.DEBUG_ALL)) {
        oldConsole.log(...args); // Log for DEBUG_ALL
    }*/
        if (level === window.DEBUG_ERROR) {
            oldConsole.error(...args);
        } else if (level === window.DEBUG_INFO ) {
            oldConsole.info(...args);
        } else if (level === window.DEBUG_ALL ) {
            oldConsole.log(...args); // Log for DEBUG_ALL
        }
}

let pstyle = 'border: 1px solid #efefef; padding: 5px'
app.layout=new w2layout({
    box: '#layout',
    name: 'layout',
    style:"width: 100%; height: 100%;",
    panels: [
        { type: 'top', style:pstyle, size:40,
            toolbar: {
                //tooltip: 'top toolbar tooltip', 
                style:"height:40px;",

            },// top toolbar
            tooltip: 'top',
        },//top type of panel
        { type: 'main', style: pstyle, html: '' },    
        {type: 'left', size: 50, resizable: true, style: pstyle, hidden:true,
            toolbar: {  
            items: [],//left toolbar items
            },//left toolbar
        },//left panel type
    ]
})//layout panels / layout object / layout function

const buildTopToolbar=(itemSelect)=>{
    let items
    switch(itemSelect){
        case 'owned-grid':
            items=[
                { type: 'menu', id: 'file-btn', text: 'File', icon: 'fa fa-file file-btn',items: [
                    { id: 'start-btn', text: 'Start', icon: 'fa fa-key', tooltip: 'open start item to explore what you can do' },
                    { id: 'file-import', text: 'Import', icon: 'fa fa-file-import', tooltip: 'Import data from a file into your tree' },
                    //{ id: 'public-import', text: 'Import Public', icon: 'fa-solid fa-file-import', tooltip: 'Import public data into your tree' },
                    { id: 'save-btn', text: 'Save', icon: 'fa fa-save', tooltip: 'Export *your* data into a downloaded file' },
                    { id: 'list-public-btn', text: 'List public items', icon: 'fa fa-people-group', tooltip: 'List public items' },
                    //{ id: 'list-btn', text: 'List own items', icon: 'fa fa-sitemap', tooltip: 'List your own (not public) items' },
                    { id: 'settings-btn', text: 'Settings', icon: 'fa fa-cog', tooltip: 'Settings and options' },
                ], tooltip: ''},
                { type: 'spacer' },
                { type: 'button', id: 'user-management', text: 'User', icon: 'fa fa-user user-management', tooltip: 'user management' },
                { type: 'html', id: 'user-input', text: 'target', tooltip: '',
                    //html:'<input type="text" id="logged-in-user"  name="user-name" type="text" class="root"  title="logged in user" value="'+app.dexieDB.cloud.currentUserId+'">'
                    html(item) { return `<input type="text" id="logged-in-user"  name="user-name" type="text" class="root"  title="logged in user" value="`+app.dexieDB.cloud.currentUserId+`">`
                        }
                },
                { type: 'button', id: 'help-btn', text: '?', icon: 'fa fa-help', tooltip: 'Help' },  
            ]
            break;
        default:
            items=[
                 //{ type: 'button', id: 'home-btn', text:'Home', icon: 'fa fa-home home-btn' },
                 { type: 'menu', id: 'file-btn', text: 'File', icon: 'fa fa-file file-btn',items: [
                    { id: 'start-btn', text: 'Start', icon: 'fa fa-key', tooltip: 'open start item to explore what you can do' },
                    { id: 'file-import', text: 'Import', icon: 'fa fa-file-import', tooltip: 'Import data from a file into your tree' },
                    //{ id: 'public-import', text: 'Import Public', icon: 'fa-solid fa-file-import', tooltip: 'Import public data into your tree' },
                    { id: 'save-btn', text: 'Save', icon: 'fa fa-save', tooltip: 'Export *your* data into a downloaded file' },
                    { id: 'list-public-btn', text: 'List public items', icon: 'fa fa-people-group', tooltip: 'List public items' },
                    //{ id: 'list-btn', text: 'List own items', icon: 'fa fa-sitemap', tooltip: 'List your own (not public) items' },
                    { id: 'settings-btn', text: 'Settings', icon: 'fa fa-cog', tooltip: 'Settings and options' },
                ], tooltip: ''},
                { type: 'button', id: 'back', text: '<', tooltip: 'prev.' },
                { type: 'button', id: 'forward', text: '>', tooltip: 'next' },
                //{ type: 'check', id: 'item1', text: 'Check', img: 'icon-page', checked: true },
                { type: 'break', id: 'break0' },

                
                { type:'label', id:'depth-label', text:'Depth', count:app.maxDepth?app.maxDepth:'all'},
                { type: 'input', id: 'depth-range', name:'depth-input', text: '', value:app.maxDepth?app.maxDepth:10, style:"height:40px;", count: 5,
                    input: {
                        style: 'width: 100px',
                        attrs: 'type="range" min="1" max="10" step="1"'
                    },
                },  
                { type:'label', id:'scale-label', text:'Scale', count:1},                
                { type: 'input', id: 'scale-range', name:'scale-input', text: '', value: 3, style:"height:40px;", count: 1,
                    input: {
                        style: 'width: 100px',
                        attrs: 'type="range" min="1" max="10" step="0.01"'
                    },
                },
                { type: 'check', id:'children-mustbe-ancestors', text:'',  icon: 'fa-solid fa-child-reaching',
                    tooltip:'Only show children who are your descendants', checked:!app.onlyAncestors},
                { type: 'break', id: 'break1' },

                //{ type: 'input', id: 'search-input', name:'search-input', text:'', value:app.search, input: {class:"search-input"}, },
                //{ type: 'button', id: 'search-button', text: '', icon: 'fa-solid fa-magnifying-glass search-btn', style:"height:40px;", tooltip: 'Search' },              
                //{ type: 'break', id: 'break2' },

                { type: 'menu-radio', id: 'colour-choice' ,name:'colourChoice', tooltip: 'Choose basis for item colouring',
                    text(item) { 
                        item.items.forEach(e=>e.checked?delete e.checked:null)
                        let which=item.items.filter(f=>f.key==app.colourKey)
                        if(which.length){
                            this.get('colour-choice').selected=which[0].id
                            return 'Colour by: '+which[0].text
                        }else{
                            return 'Colour by: '
                        }
                    },
                    hidden:false,
                    items: [
                { id: 'item1', group: '1', text: 'sex', key:'sex', icon: 'fa-solid fa-venus-mars', tooltip: 'Colour by sex'},
                { id: 'item2', group: '1', text: 'Country of birth', key:'cob', icon: 'fa-solid fa-baby', tooltip: 'Colour by country of birth'},//checked: true
                { id: 'item3', group: '1', text: 'Descendant', key:'descendant', icon: 'fa-solid fa-tree', tooltip: 'Colour if descendant of root-person'},
                { id: 'item4', group: '1', text: 'Data Source', key:'source', icon: 'fa-solid fa-database', tooltip: 'Colour based on who owns the data in the database'},
                    ]},
                { type: 'spacer' },
                //{ type:'label', id:'user-label', text:'User:'},
                { type: 'button', id: 'user-management', text: 'User', icon: 'fa fa-user user-management', tooltip: 'user management' },
                //{ type: 'input', id: 'logged-in-user', name:'user-name', tooltip: 'user name', class:"root",},
                //}, //html:
                { type: 'html', id: 'user-input', text: 'target', tooltip: '' , value:app.dexieDB.cloud.currentUserId,
                    //'html:'<input type="text" id="logged-in-user"  name="user-name" type="text" class="root"  title="logged in user" value="'+app.dexieDB.cloud.currentUserId+'">',
                    html(item) { 
                        return `<input type="text" id="logged-in-user"  name="user-name" type="text" class="root"  title="logged in user" 
                        value="`+app.dexieDB.cloud.currentUserId+`"
                        onclick="app.openUserManager()"
                        onchange="app.openUserManager(this.value)"
                        ">`
                    },
                    
                    render:(a,b)=>{console.log('********U')}
                    //onkeydown:(ev)=>{console.log('m')},
                },
                { type: 'button', id: 'help-btn', text: '?', icon: 'fa fa-help', tooltip: 'Help' }, 
                //{ type: 'button', id: 'user-management', text: 'manage', icon: 'fa fa-user user-management', tooltip: 'user management' },
            ]//items in main toolbar
        }



//window.openUserManager=(value)=>{
//    console.log(DEBUG_ALL,'user-management')
//    app.openUserManager(value)
//}

    app.w2ui['layout'].get('top').toolbar.items=items
    //app.w2ui['layout'].set('top',{hidden:false})
    //app.w2ui['layout'].get('top').toolbar.items.onkeydown=function (ev){console.log("n")}
        
    //userInput.addEventListener('keydown',ev=>{ev.preventDefault()})
    //if(userInput){
    //    userInput.value=app.dexieDB?.cloud.currentUserId
    //    console.log(DEBUG_ALL,`user-management: ${app.dexieDB?.cloud.currentUserId}`)
    //    userInput.removeEventListener('click',ev=>openUserManager())
    //    userInput.addEventListener('click',ev=>openUserManager())
//
    //}else{
    //    console.log(`dom logged-in-user not available`)
    //}
    app.w2ui['layout'].get('top').toolbar.render()
    app.w2ui['layout'].showToolbar('top')
    /*let userInput=document.getElementById('logged-in-user')
    userInput.addEventListener("change",(ev,b)=>{
        ev.preventDefault()
    })*/
    /*
    query('#logged-in-user').each(elem=>{
        //elem.addEventListener("mousedown",ev=>ev.preventDefault())
        //elem.addEventListener("keydown",ev=>ev.preventDefault())
        elem.addEventListener("change",ev=>{
            console.log('change')
        })
    });*/

    //app.w2ui['layout'].get('top').toolbar.on=function("*",event) {
    //    console.log('gotoit? '+event.target.name)
    //    if (event.target == 'bt4') this.clear();//w2ui.form.clear();
    //    if (event.target == 'bt5') this.save();
    //    if (event.target == 'editChildrenFlag') console.log('Edit Children');
    //}     

};app.buildTopToolbar=buildTopToolbar;

const buildLeftToolbar=(itemSelect)=>{
    let items
    switch(itemSelect){
        case 'owned-grid':
            items=[             
                { type: 'button', id: 'home-btn', icon: 'fa fa-sitemap home-btn', tooltip:'Show the tree view from the root item' },//fa fa-home  'Goto the home item'
                { type: 'new-line',},
                { type: 'check', group:'1', id: 'btn-include-public-grid', icon: 'fa fa-people-group', tooltip:'Include public items',checked:app.includePublic},//fa fa-sitemap
                { type: 'new-line',},
                //{ type: 'button', id: 'btn-new', text: '', icon: 'fa-solid fa-plus',tooltip:'Add an item to the tree' },//<i class="fa-solid fa-pen-to-square"></i>
                //{ type: 'new-line',},
                { type: 'menu', id: 'menu-show-buttons', text: '', icon: 'fas fa-bullseye',tooltip:'toggle columns',
                    items:[
                        //{ id: 'btn-show-id', text: 'id', tooltip: 'id' },
                        { id: 'btn-show-uid', text: 'uid', tooltip: 'uid' },
                        { id: 'btn-show-name', text: 'Name', tooltip: 'Nameid' },
                        { id: 'btn-show-label', text: 'Label', tooltip: 'Label' },
                        { id: 'btn-show-parents', text: 'Parents', tooltip: 'Parents' },
                        { id: 'btn-show-children', text: 'Children', tooltip: 'Children' },
                        { id: 'btn-show-spouses', text: 'Spouses', tooltip: 'Spouses' },
                        { id: 'btn-show-others', text: 'Others', tooltip: 'Others' },
                        { id: 'btn-show-born', text: 'Born', tooltip: 'Born' },//icon: 'fa fa-key'
                        { id: 'btn-show-died', text: 'Died', tooltip: 'Died' },
                        { id: 'btn-show-ancestor', text: 'Ancestor', tooltip: 'Ancestors' },
                    ]
                 },//<i class="fa-solid fa-pen-to-square"></i>

            ]            
            break;
        case 'tree-view':
        default:
            items=[             
                //{ type: 'button', id: 'home-btn', icon: 'fa fa-sitemap home-btn', tooltip:'Show the tree view from the root item' },//fa fa-home  'Goto the home item'
                //{ type: 'new-line',},
                { type: 'button', id: 'btn-own-grid', icon: 'fa fa-people-group', tooltip:'List your *private items' },//fa fa-sitemap
                { type: 'new-line',},
                { type: 'check', id: 'btn-select', group:'2', text: '', icon: 'fa-solid fa-square-up-right nav-or-edit',tooltip:'Click on item in tree to select', },
                { type: 'new-line',},
                { type: 'check', id: 'btn-edit', group:'2', text: '', icon: 'fa-solid fa-pen-to-square dom-edit',tooltip:'Click on item in tree to edit' },//<i class="fa-solid fa-pen-to-square"></i>
                { type: 'new-line',},
                { type: 'button', id: 'btn-new', text: '', icon: 'fa-solid fa-plus',tooltip:'Add an item to the tree' },//<i class="fa-solid fa-pen-to-square"></i>
                { type: 'new-line',},
                //{ type: 'check', id: 'btn-dom', group:'2', text: '', icon: 'fa-solid fa-scissors dom-hide',tooltip:'Click on item in tree to hide' },
                //{ type: 'new-line'},
                //{ type: 'check', id: 'btn-copy', group:'2', text: '', icon: 'fa-solid fa-copy', hidden:false, tooltip:'Click on item in tree to take ownership' },
                //{ type: 'new-line'},
                { type: 'check', id: 'btn-delete', group:'2', text: '', icon: 'fa-regular fa-trash-can', hidden:false, tooltip:'Click on item in tree to delete it from your database' },
                { type: 'new-line'},
                //{ type: 'check', id: 'btn-share', group:'2', text: '', icon: 'fa-solid fa-share-nodes', hidden:false, tooltip:'take ownership, share or publish item' },
                //{ type: 'new-line', hidden:true},
                
                //{ type: 'button', id: 'btn-focus', text: '', icon: 'fa-solid fa-arrows-spin dom-focus', hidden:true, tooltip:'tab between duplicates' },
                //{ type: 'new-line', hidden:true},
                //{ type: 'button', id: 'btn-untruncate', text: '', icon: 'fa-solid fa-unlock dom-untruncate', hidden:true, tooltip:'untruncate parents' },
                //{ type: 'new-line', hidden:true},

                //{ type: 'button', id: 'btn-start-tree', text: '', icon: 'fa-solid fa-diagram-project', hidden:false, tooltip:'Start a completely new tree' },
                //{ type: 'new-line', hidden:true},
                //{ type: 'button', id: 'btn-add-parents', text: '', icon: 'fa-solid fa-person-cane', hidden:false, tooltip:'Add parents' },
                //{ type: 'new-line', hidden:true},
                //{ type: 'button', id: 'btn-add-children', text: '', icon: 'fa-solid fa-children', hidden:false, tooltip:'Add children' },
                
                //{ type: 'new-line'},
            ];
            if(app.unlock){
                items.push({ type: 'check', id: 'btn-unlock', group:'3', text: '', icon: 'fa-solid fa-unlock',
                     hidden:false, tooltip:'Click on private item in tree to get private data',
                        checked:app.unlockChecked
                    },)
            }
    }//switch
    //app.w2ui['layout'].toggleToolbar('left')
    
    app.w2ui['layout'].get('left').toolbar.items=items
    app.w2ui['layout'].get('left').toolbar.render()
    app.w2ui['layout'].showToolbar('left')
    app.w2ui['layout'].set('left',{hidden:false})
    //app.w2ui['layout'].get('left').toolbar.items
    //let old=app.w2ui['layout'].get('left').toolbar.items
    //app.w2ui['layout'].get('left').toolbar.items=[]
    //for(let i=0;i<7;i++)app.w2ui['layout'].get('left').toolbar.items.push(items[i]);
    
    //app.w2ui['layout'].get('left').toolbar.items=[]
    //not necc app.w2ui['layout'].get('left').toolbar.refresh()
'change'


};app.buildLeftToolbar=buildLeftToolbar;

const placeTreeDiv=()=>{
    let outer=document.createElement('div');
    outer.id='triggerit'
    let scalingWrapper=document.createElement('div');
    scalingWrapper.id='scalingWrapper'
    scalingWrapper.classList.add('scaling-wrapper')
    outer.classList.add('outer-container')
    let inner=document.createElement('div');
    inner.id='treediv';
    inner.classList.add('tf-tree','inner-content');
    if(app?.colourKey)inner.classList.add(app.colourKey)

    outer.appendChild(scalingWrapper)
    scalingWrapper.appendChild(inner)
    return outer
}
app.placeTreeDiv=placeTreeDiv

const replaceTree=async (uids)=>{
    let triggerit=document.getElementById('triggerit')
    if(triggerit)triggerit.remove()
    //removeAllTooltips()
    //w2tooltip.get().forEach(f=>{try{
    //    let tt=w2tooltip.get(f)
    //    if(tt && tt?.displayed)tt.hide()
    //}catch(err){}})

    if(uids&&uids.length){   
        app.treeRoot=uids
    }
    //console.log(DEBUG_ALL,'here')
    if(document.getElementById('layout')){
        app.layout.html('main',placeTreeDiv().outerHTML)
        return redrawTree()
    }else{
        return devHackNoLayout()
    }
}
app.replaceTree=replaceTree;

app.openPublicImport=()=>{
    app.w2alert('function unavailable') 
}

const listPublicItems=()=>{
    app.w2alert('function unavailable')
}

const openSettings=async()=>{
    app.settingsForm = new w2form({
        box: '#content',
        name: 'settingsForm',
        style: 'border: 0px; background-color: transparent;',
        record: {
            childrenByImplication: app.checkChildrenCount,
            unlock: app.unlock,
            shareWith: app.shareWith
        },
        fields: [//https://w2ui.com/web/docs/2.0/w2form.fields
            //https://w2ui.com/web/demos/#/form/3
                {field:'childrenByImplication', type:'checkbox',
                    html: {
                        label: 'Children determined by who their parents are:',
                        //attr: 'style="border: 0px solid green"',
                        //html:'<input id="settings-check" type="checkbox" data-name="child-checked" class="w2ui-input" style="position: relative; top: 2px; left: 4px">'
                    },
                },
                {field:'unlock', type:'checkbox', hidden:true,
                    html: {
                        label: 'Lookup item from JSON:',
                        //attr: 'style="border: 0px solid green"',
                        //html:'<input id="settings-unlock" type="checkbox" data-name="lock-checked" class="w2ui-input" style="position: relative; top: 2px; left: 4px">'
                    },
                },
                {field:'shareWith', type:'email',
                    html: {
                        //caption:'myCaption',
                        label: 'Share email:',
//                        attr: 'style="border: 0px solid green"',
//render(options) {return `<input id="settings-share" type="text" data-name="shareWith" class="w2ui-input" 
//        style="position: relative; top: 2px; left: 4px" placeholder="user email">`}
                        },
                }
            ],
        actions: {
            //buttonName(){},
            //reset(){this.clear()},
            //save(){}
        }
        })
        w2popup.open({
            title   : 'Settings',
            body    : '<div id="form" style="width: 100%; height: 100%;"></div>',
            style   : 'padding: 15px 0px 0px 0px',
            width   : 500,
            height  : 380,
            showMax : true,
            async onToggle(event) {
                await event.complete
                w2ui.settingsForm.resize();
            },
            onClose(event){
                if(w2ui?.settingsForm)w2ui.settingsForm.destroy();
                else console.log(DEBUG_ALL,'under what circumstances is this the case?');  
            },
        })
        .then((event) => {
            console.log(DEBUG_ALL,'form')
            w2ui.settingsForm.render('#form')
            let check=query('#childrenByImplication')
            let unlock=query('#unlock')
            let shareWith=query('#shareWith')
            check.on('click',function(event){
                app.checkChildrenCount=event.target.checked?true:false;
                let o={key:'check',value:app.checkChildrenCount}
                if(dbs?.check)o.id=dbs.check;
                dbs.put(o).then(id=>dbs.check=id)
                .finally(f=>{
                    app.settingsForm.record.check=app.checkChildrenCount
                    app.settingsForm.refresh()
                })
            }),
            unlock.on('click',function(event){
                app.unlock=event.target.checked?true:false;
                let o={key:'unlock',value:app.unlock}
                if(dbs?.unlock)o.id=dbs.unlock;
                dbs.put(o).then(id=>dbs.unlock=id)
                .finally(f=>{
                    app.settingsForm.record.unlock=app.unlock
                    app.settingsForm.refresh()
                })
            })
            shareWith.on('change',function(event){
                app.shareWith=event.target.value
                let o={key:'shareWith',value:app.shareWith}
                if(dbs?.shareWith)o.id=dbs.shareWith;
                dbs.put(o).then(id=>dbs.shareWith=id)
                .finally(f=>{
                    app.settingsForm.record.shareWith=app.shareWith
                    app.settingsForm.refresh()
                })
            })
            //check[0].checked=app.checkChildrenCount
            //unlock[0].checked=app.unlock
            app.settingsForm.refresh()
        });

}

class MyControl extends w2field {
    constructor(type, options) {
        super(type, options)

    }

    init() {

    }

    render(el) {
        return `<span data-name="uid" title="my type">&nbsp</span>`
    }
}

const openEdit=async(elem, item)=>{
    //if (w2ui.editItem){
//}else{
    console.log(DEBUG_ALL,'openEdit')
    let myControl=new MyControl('myControl',{mu:'was here'})
    app.editForm = new w2form({
        box: '#content',
        name: 'editItem',
        style: 'border: 0px; background-color: transparent;',
        autosize: true,
//https://w2ui.com/web/docs/2.0/w2form.autosize
//https://w2ui.com/web/docs/2.0/w2form.fields
//https://w2ui.com/web/demos/#/form/23   better groups
/*
toolbar: {
    items: [
        {id:'editChildrenFlag', type: 'html', label:'Edit Children', text:'text',value:false,
            html(item) {
//<input size="10" onchange="var el = w2ui.toolbar.set(\'item5\', { value: this.value });" '+
//${this.set('editChildrenFlag', {value:this.value })}
                return `<div style="padding: 3px 10px 3px 1px">${item.label}<input size="10" type="checkbox" onchange=""
                        style="padding: 3px; border-radius: 2px; border: 1px solid silver" value="${item.value}"/></div>`
            }
        }
        //{ id: 'bt1', type: 'button', text: 'Button 1', img: 'icon-folder' },
        //{ id: 'bt2', type: 'button', text: 'Button 2', img: 'icon-folder' },
        //{ id: 'bt3', type: 'spacer' },
        //{ id: 'bt4', type: 'button', text: 'Reset', img: 'icon-page' },
        //{ id: 'bt5', type: 'button', text: 'Save', img: 'icon-page' }
    ],
    onClick(event) {
        if (event.target == 'bt4') this.clear();//w2ui.form.clear();
        if (event.target == 'bt5') this.save();
        if (event.target == 'editChildrenFlag') console.log('Edit Children');
    }
},*/
fields:{
'sex':{type:'select', 
    options:{items:['Unknown' ,'Male','Female'],selected:'Unknown'},
    html:{
        label: 'Sex',
        attr: 'style="width:100px"',
        span: 2,
    //value: {
    //    attr: '',//'placeholder="Type value..." style="width: 250px"',
    //    text: ' - ok'
    //}
}},
'item.uid':{type:'input', html:{
    label: 'UID',
    span: 2,
    attr: 'style="width:260px"',
    value: {
        attr: 'style="width:260px"',
        //text: ' - ok'
    }}}, 
'item.Name':{type:'textarea', html:{
    label: 'Name',
    span: 2,
    attr: 'style="width:500px;height:30px"',
    value: {
        attr: 'style="width: 300px"',
        //text: ' - ok'
    }}}, 
'item.Label':{type:'textarea', html:{
    label: 'Label',
    span: 2,
    attr: 'style="width:500px;height:200px"',
    value: {
        attr: 'style="width: 300px"',
        //text: ' - ok'
    }}}, 
'arrayOfParents' : {
    type: 'array', 
    html: {         
        //group: 'Parents',
        //anchor : '#parent-list',
        label: 'Parents',
        span: 3,
        map:{attr:'style="background-color:pink"',},
        value:{attr:'style="background-color:purple"',},//input field attrs for each key field
        //attr:'style="height:300px"',
        render(options) {
            let { ind, empty } = options
            return empty?`<i class="fa fa-plus add-parent" title="Add new entry"
            onClick="let ev=new Event('add-parent');
            w2ui.editItem.listeners[0].handler(ev, this)"></i>`:
            `<input type="text" data-name="uid" placeholder="uid not found..." class="w2ui-input">
            <span style="padding: 5px"></span>
            <input type="text" data-name="Name" placeholder="Name mot found..." class="w2ui-input" 
            title="${w2ui.editItem.record.arrayOfParents[ind].uid}">
            <i class="fa fa-unlink" title="Unlink this parent of item" 
            data-uid="${w2ui.editItem.record.arrayOfParents[ind].uid}" 
            onClick="let ev=new Event('unlinkParent');
            w2ui.editItem.listeners[0].handler(ev, this)">
            </i><!--i class="fa fa-trash"></i-->${createButton(ind,'parent')}`
        },
    }, 
},

'arrayOfChildren' : {
    type: 'array', 
    html: {         
        //group: 'Parents',
        //anchor : '#parent-list',
        label: 'Children',
        span: 3,
        map:{attr:'style="background-color:pink"',},
        value:{attr:'style="background-color:purple"',},//input field attrs for each key field
        //attr:'style="height:300px"',
        render(options) {
            let { ind, empty } = options
            return empty?`<i class="fa fa-plus add-child" title="Add new entry"
            onClick="let ev=new Event('add-child');
            w2ui.editItem.listeners[0].handler(ev, this)"></i>`:
            `<input type="text" data-name="uid" placeholder="uid not found..." class="w2ui-input">
            <span style="padding: 5px"></span>
            <input type="text" data-name="Name" placeholder="Name not found..." class="w2ui-input" 
            title="${w2ui.editItem.record.arrayOfChildren[ind].uid}">
            <i class="fa fa-unlink" title="Unlink this child from item" 
            data-uid="${w2ui.editItem.record.arrayOfChildren[ind].uid}" 
            onClick="let ev=new Event('unlinkChild');
            w2ui.editItem.listeners[0].handler(ev, this)">
            </i><!--i class="fa fa-trash"></i-->${createButton(ind,'child')}`
        },
    }, 
},

},
    //],    
        actions: {
            //reset() {
            //this.clear()
            //},
            save() {
                //this.save()
                console.log(DEBUG_ALL,'edit-form save')
                let changes=this.getChanges()
                let recItem=w2ui.editItem.record.item
                recItem.Parents=w2ui.editItem.record.arrayOfParents.map(m=>m.uid)
                recItem.Children=w2ui.editItem.record.arrayOfChildren.map(m=>m.uid)
                let o={
                    uid:recItem.uid,
                    Name:recItem?.Name?recItem.Name:'',
                    Label:recItem?.Label?recItem.Label:'',
                    Parents:recItem?.Parents?recItem.Parents:"[]",
                    Children:recItem?.Children?recItem.Children:"[]",
                    Spouses:recItem?.Spouses?recItem.Spouses:"[]",
                    actions:recItem?.actions?recItem.actions:null,
                    AncestorOf:recItem?.AncestorOf?recItem.AncestorOf:null,
                    hidden:this.hidden?.hidden?recItem.hidden:null,
                    //truncate
                    sex:recItem?.sex?recItem.sex:null,
                    Born:recItem?.Born?recItem.Born:null,
                    Died:recItem?.Died?recItem.Died:null,
                    created_at:recItem?.created_at?recItem.created_at:null,
                    updated_at:recItem?.updated_at?recItem.updated_at:null,
                    notFound:recItem?.notFound?null:null,
                    }//original only set if there is a change
                    for (const [key, value] of Object.entries(changes)){
                        switch(key){//These are the field Names
                            case'id':
                                break;
                            case'Name':
                                o.Name=value
                                this.item.Name=value
                                break;
                            case'Label':
                                o.Label=value
                                this.item.Label=value
                                break;
                            case 'sex':
                                o.sex=['Unknown','Male','Female'].indexOf(changes.sex)
                                this.sex=o.sex
                                break;
                        }}
                    let isItNew=''
                    if(Object.keys(o).length>1){//len 1 would mean only key is ID, so no changes
                        updateUid(o)  
                    }else{
                        let message=`There were no changes made so no need to update`
                            console.log(DEBUG_ALL,message)
                            w2alert(message)
                    }
                //w2ui.editItem.record.arrayOfParents.forEach(pRec=>{if(pRec?.newItem)updateUid(pRec.newItem);})  
                //w2ui.editItem.record.arrayOfChildren.forEach(cRec=>{if(cRec?.newItem)updateUid(cRec.newItem);})  
                app.w2popup.close() 
                },
            Cancel(){
                //console.log(DEBUG_ALL,'form closing')
                app.w2popup.close()
            }
        }
    })

    const parseValue=(value)=>{//private to editForm
        let uidArray=[]
        try{
            value=value.replace(/^"/,'').replace(/"$/,'')
            uidArray=JSON.parse(value)
        }catch(error){
                w2alert(error.message)
        }
        return uidArray
    }
    
    const popit=()=>{
        w2popup.open({
            title   : 'Edit',
            body    : '<div id="form" style="width: 100%; height: 100%;"></div>',
            style   : 'padding: 15px 0px 0px 0px',
            width   : 640,
            height  : 600,
            showMax : true,
            async onToggle(event) {
                await event.complete
                w2ui.editItem.resize();
            },
            onClose(event){
                if(w2ui?.editItem)w2ui.editItem.destroy();
                else console.log(DEBUG_ALL,'under what circumstances is this the case?');  
            }
        })
        .then((event) => {
            w2ui.editItem.render('#form')
        });
    }

    const getItemsFromUids=async(recs)=>{
        return await Promise.all(recs.map(async rec=>{
                if(rec?.newItem){
                    return rec
                }else{
                let all=await db.where('uid').equals(rec.uid).toArray()
                let item=await app.getItem(all, rec.uid)
                if(!item)return {uid:'', Name:rec.uid, Label:'uid not found', notFound:true}
                return item
                }
            }
        ))
    }

    w2ui.editItem.record={'item':item,'arrayOfParents':[],'arrayOfChildren':[],'sex':['Unknown' ,'Male','Female'][item.sex]}
    w2ui.editItem.record.item.Label=app.processLabel(w2ui.editItem.record.item.Label).replaceAll(/<br>/igm,'\n')//for textarea
 
    getItemsFromUids(item.Parents.map(m=>({uid:m}))).then(all=>{
        w2ui.editItem.record.arrayOfParents=(all ?? [])
        getItemsFromUids(item.Children.map(m=>({uid:m}))).then(all=>{
            w2ui.editItem.record.arrayOfChildren=(all ?? [])
            popit()
        })
    })

    const updateParents=()=>{
        getItemsFromUids(w2ui.editItem.record.arrayOfParents.map(m=>m)).then(all=>{
            w2ui.editItem.record.arrayOfParents=all
            w2ui.editItem.render()
            })
    }

    
    const updateChildren=()=>{
        getItemsFromUids(w2ui.editItem.record.arrayOfChildren.map(m=>m)).then(all=>{
            w2ui.editItem.record.arrayOfChildren=all
            w2ui.editItem.render()
            })
    }

    const addItem=(node,parentOrChild)=>{
        let uidInput, isParent=parentOrChild=='parent'?true:false
        node.style.display='none'
        node.after(uidInput=document.createElement('input'))
        uidInput.type='text'
        uidInput.title='existing uid to link or Name to create new '+
            isParent?'parent':'child'
        uidInput.placeholder='uid or Name'
        uidInput.classList.add(isParent?'add-parent-input':'add-child-input')
        uidInput.onchange=event=>{
            console.log(DEBUG_ALL,isParent?'add-parent-input':'add-child-input')
            let uid=uidInput.value
            let which=isParent?w2ui.editItem.record.arrayOfParents:w2ui.editItem.record.arrayOfChildren
            if(!which)which=isParent?w2ui.editItem.record.arrayOfParents=[]:w2ui.editItem.record.arrayOfChildren=[]
            which.push({uid})
            isParent?updateParents():updateChildren() //with the what was typed in     
        }
        uidInput.focus()
    }

    w2ui.editItem.on('*',function(event, elem){
        let ind, rec
        switch(event.type){
            case'unlinkParent':
                w2ui.editItem.record.arrayOfParents=w2ui.editItem.record.arrayOfParents.filter(f=>f.uid!=elem.dataset.uid)
                updateParents()
            break;
            case'unlinkChild':
                w2ui.editItem.record.arrayOfChildren=w2ui.editItem.record.arrayOfChildren.filter(f=>f.uid!=elem.dataset.uid)
                updateChildren()
            break;
            case 'add-parent':
                addItem(elem,'parent')
            break;
            case 'add-child':
                addItem(elem,'child')
                break;
            case 'create-parent-item':
                console.log(4,`create-parent-item`)
                elem.classList.remove('fa-plus');elem.classList.add('fa-check')
                ind=parseInt(elem.dataset.ind)
                rec=w2ui.editItem.record.arrayOfParents[ind]
                createItem(ind, rec, elem)
                updateParents()
                //event.preventDefault()
            break;
            case 'create-child-item':
                console.log(4,`create-child-item`)
                elem.classList.remove('fa-plus');elem.classList.add('fa-check') 
                ind=parseInt(elem.dataset.ind)
                rec=w2ui.editItem.record.arrayOfChildren[ind]
                createItem(ind, rec, elem)
                updateChildren()
                //event.preventDefault()
            break;   
        }
        })

    const createButton=(ind, parentOrChild)=>{//if no items in the database match the uid then create a 'plus' button to create a new item
        let record, 
        isParent=parentOrChild=='parent'?true:false, 
        indStr='data-ind="'+ind+'"',
        poc='data-poc="'+parentOrChild+'"'
        record=isParent?w2ui.editItem.record.arrayOfParents:w2ui.editItem.record.arrayOfChildren;
        let evId=isParent?`onClick="let ev=new Event('create-parent-item');w2ui.editItem.listeners[0].handler(ev, this)"`:`onClick="let ev=new Event('create-child-item');w2ui.editItem.listeners[0].handler(ev, this)"`
        let str=record[ind]?.notFound?`<i class="fa fa-plus" title="Create this ${isParent?'Parent':'Child'} now?" ${poc} ${indStr} ${evId}></i>`:``
        console.log(`createButton ${isParent?'Parent':'Child'} index:${ind}`)
        return str
    }

    const createItem=async(ind, rec, elem)=>{
        console.log('createItem ')
        let isParent=elem.dataset.poc=='parent'?true:false //parentOrChild
        //let ind=parseInt(c.dataset.ind)
        let o={}
        let isUid=/[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}/gm.exec(rec.uid)
        if(isUid){//if a valid uid but not existing in current db then use it
            o.Name=rec.Name
            o.uid=rec.uid
        }else{
            o.Name=rec.uid
            //system generates a new uid
        }
        if(isParent)//parent
            o.Children=[item.uid];
        else
            o.Parents=[item.uid];
        let newItem=createNewItem(o)
        if(isParent){
            w2ui.editItem.record.arrayOfParents[ind].uid=newItem.uid;
            w2ui.editItem.record.arrayOfParents[ind].Name=newItem.Name;
            w2ui.editItem.record.arrayOfParents[ind].newItem=newItem;
        }else{ 
            w2ui.editItem.record.arrayOfChildren[ind].uid=newItem.uid;
            w2ui.editItem.record.arrayOfChildren[ind].Name=newItem.Name;
            w2ui.editItem.record.arrayOfChildren[ind].newItem=newItem;
        }


        if(false)return db.put(newItem).then(async id=>{
            console.log(`created new item id:${id} uid:${newItem.uid} Name:${newItem.Name}`)
            if(isParent){//We have created a parent to item where Item is the item being edited. It needs to be saved
                item.Parents.push(newItem.uid)
            }else{//child
                item.Children.push(newItem.uid)
            }
            return await updateUid(item).then(t=>{//but it's been edited
                console.log('updated item')
            })
        })
        .catch(err=>w2alert(`failed to create new item ${err.message}`))
        .finally(f=>{
        })
    };  
}//end editForm
//a=await db.where('id').startsWith('prs').delete()

let contextOptions=[
{ type: 'button', id: 'btn-select', text:'Select', icon: 'fa-solid fa-square-up-right', tooltip:'Select this item', onClick:"console.log(DEBUG_ALL,1)" },
{ type: 'button', id: 'btn-goto', text:'Goto', icon: 'fa-solid fa-arrow-up-short-wide', tooltip:'Goto this item' },
{ type: 'button', id: 'btn-edit', text:'Edit', icon: 'fa-solid fa-pen-to-square', tooltip:'Edit this item' },
{ type: 'button', id: 'btn-hide', text:'Hide', icon: 'fa-solid fa-ban', tooltip:'Hide this branch' },
{ type: 'button', id: 'btn-unhide', text:'Unhide', icon: 'fa-solid fa-check', tooltip:'Unhide after this branch' },
{ type: 'button', id: 'btn-delete', text:'Delete', icon: 'fa-regular fa-trash-can', tooltip:'Delete this item' },
{ type: 'button', id: 'btn-spouse', text:'Spouse', icon: 'fa-regular fa-heart', tooltip:'show spouse' },
{ type: 'button', id: 'btn-share', text:'Share', icon: 'fa-solid fa-people-arrows', tooltip:'share item' },
//{ type: 'button', id: 'btn-copy', text:'Copy', icon: 'fa-solid fa-copy', tooltip:'Copy this item' },
//{ type: 'button', id: 'btn-own', text:'Own', icon: 'fa-solid fa-own', tooltip:'Own this item' },
//{ type: 'button', id: 'btn-tree', text:'Own tree', icon: 'fa-solid fa-own-tree', tooltip:'Own this item and tree' },
//{ type: 'button', id: 'btn-follow-dup', text:'Next dup', icon: 'fa-solid fa-twin', tooltip:'focus on next duplicate' },
]

document.addEventListener("contextmenu", (ev) => {
    if(ev.target.classList.contains('tf-nc')){
        if(app.contextMenu)console.log(DEBUG_ALL,'node context')
        let useItems=contextOptions  
        if(ev.target.classList.contains('duplicate')){
            useItems=[...useItems,
                ...[{type: 'button', id: 'btn-follow-dup', text:'Next dup', icon: 'fa-solid fa-person-walking-arrow-right', tooltip:'focus on next duplicate'},
                    {type: 'button', id: 'btn-move-dup', text:'Move dup', icon: 'fa-solid fa-person-walking-dashed-line-arrow-right', tooltip:'move the expanded duplicate here' }
                ]]
        } 
        let menu=w2menu.show({
            name: 'context-menu',
            //contextMenu: true,
            anchor: ev.target,
            //match: 'contains',
            //postion:
            arrowSize:0,
            altRows: true,
            items: useItems
        })
            .select((event) => {
                let span=event.owner.anchor
                switch(event.detail.item.id){
                    case 'btn-select':btnSelect(span);break;
                    case 'btn-goto':btnGoto(span.item.uid);break;
                    case 'btn-edit':btnEdit(span);break;
                    case 'btn-hide':btnHide(span);break;
                    case 'btn-unhide':btnUnhide(span);break;
                    case 'btn-delete':btnDelete(span);break;
                    case 'btn-spouse':btnSpouse(span);break;
                    case 'btn-share':btnShare(span);break;
                    case 'btn-copy':btnCopy(span);break;
                    case 'btn-own':btnOwn(span);break;
                    case 'btn-tree':btnTree(span);break;
                    case 'list-btn':updateOwnedGrid();break;
                    case 'btn-follow-dup':btnFollowDup(span);break;
                    case 'btn-move-dup':btnMoveDup(span);break;
                    default:
                        console.log(DEBUG_ALL,`case ${event.detail.item.id} not handled`)    
                    break;
                }
            })  
        ev.preventDefault();
        }
    })

    const afterUpdate=(item)=>{
        //update DOM
        console.log(DEBUG_ALL,'afterUpdate')
        let spans=Array.from(app.query('span.tf-nc[data-uid="'+item.uid+'"]'))//.tf-nc ++ child
        spans.forEach(span=>{updateSpan(span, item)})
        app.w2popup.close()
        //app.show(null,app.treeRoot) //This leads to an error as new value read from persons fails
    }

    const updateUid=async(item)=>{//o is the item and any changes, item is the old [public] one to be changed
        let o=item
        if(o?.id)delete o.id
        if(o?.realmId)delete o.realmId
        if(o?.owner)delete o.owner
        return app.dexieDB.persons.where('uid').equals(item.uid).toArray(all=>{//check if any records have this uid
            if(!all.length){//none of them do so create a new one
                //console.log(DEBUG_ALL,'There are zero records where uid='+o.uid);
                //return Promise.reject({message:'There are zero records where uid='+o.uid})
                return app.dexieDB.persons.put(o)// create a new item for this uid in the current user context for owner and realmId
                    .then(p=>{
                        console.log(DEBUG_ALL,`we created a new record for uid:'${item.uid}' because it was not found in our data (you might need to import it to replace this one): ${p}`)
//prs0P9wrv|gzYaQtgprqjcwYdnycal, e2d30772-c94f-4142-9685-edd9e68f039f
//prs0P9wrw2|3h3tqauwnHWDQ_5Ucal, 79455d12-a39d-415a-b027-0fb6014461b2
//prs0P9wrw3hSnXBY3x3_qrlO2xFcal, ba80a4ad-860d-4ef5-bd96-6dfb70bc361d
                    })
                    .catch(err=>{
                        console.log(DEBUG_ALL,`we tried to create a new record for uid:'${item.uid}' because it was not found in our data but had an error:${err.message}`)
                    })
            }else{    
            //at least one existing record matches the uid so modify it
            let realm=app.dexieDB.cloud.currentUserId
            let belongsToUsers=all.filter(f=>f?.realmId==realm)
            let isPublic=all.filter(f=>f?.realmId=='rlm-public')
            let id//The records matching the uid to update 
            if(belongsToUsers.length){
                if(belongsToUsers.length>1){
                    console.log(DEBUG_ALL,`We have more than one record for this user that equals this uid ${o.uid} ???`)
                }
                o.id=belongsToUsers[0].id//take the first one
                //db.update(o.id,o).then(p=>{
                return db.where('id').equals(o.id).modify(o).then(p=>{
                    console.log(DEBUG_ALL,`updated id: ${o.id} uid:${o.uid} ${o.Name}`)
                })
                .catch(err=>{
                    console.log(DEBUG_ALL,`err:${err.message} updating id:${o.id} uid:${o.uid}`)
                })
            }else if(isPublic.length){
                if(isPublic.length>1){
                    console.log(DEBUG_ALL,`We have more than one rlm-public record (for this user) that equals this uid ${o.uid} ???`)
                }
                //This mod wil be temporary so lets notify the user
                //create a new record
                return app.dexieDB.persons.put(o)
                .then(p=>{
                    console.log(DEBUG_ALL,`creating a new record (put) new id: ${p}`)
                })
                .catch(err=>{
                    console.log(DEBUG_ALL,`put err:${err.message} creating a new record or uid:${item.uid}`)
                })
            }else{// their are records that neither belong to users nor public
                return Promise.reject({})
            }
        }
    }).finally(f=>{
        afterUpdate(item)
    })
    };app.updateUid=updateUid    

const btnSelect=(span)=>{
    query('.selected').each(q=>{q.classList.remove('selected')});span.classList.add('selected')
    //span.select();
    //span.setSelectionRange(0, 99999); // For mobile devices
     // Copy the text inside the text field
    //navigator.clipboard.writeText(span.value);
    navigator.clipboard.writeText(span.item.uid);
}
const btnGoto=(uid)=>{
    app.treeRoot=[uid];
    app.setURLSearchParam('uid',uid)
    redrawTree()
}
const btnEdit=(span)=>{openEdit('',span.item)}
const btnHide=(span)=>{
    //hideItem(span.item)
    let o=span.item
    delete o.id
    delete o.realmId
    o.truncate=true
    updateUid(o, span.item)
}
const btnUnhide=(span)=>{
    let o
    if(span.item.truncate){
        updateUid((span.item.truncate=null,span.item))//top level was truncated
    }else{
        db.toArray(a=>a.filter(f=>f?.truncate).filter(f=>span.item.Parents.indexOf(f.uid)>-1)).then(itemsThatAreTruncated=>{
            //itemsThatAreTruncated.forEach(item=>db.update(item.id,{truncate:null}))//we know we can do this because no pulic items have a truncate
            itemsThatAreTruncated.forEach(item=>updateUid((item.truncate=null,o=item)))
            /*itemsThatAreTruncated.forEach(item=>{
                let o=item
                delete o.id
                delete o.realmId
                o.truncate=true
                updateUid(o, span.item)
            })*/
        }).finally(f=>{redrawTree()})}}

const btnDelete=(span)=>{
    deleteItem(span.item)//.finally(f=>redrawTree());//no need 
}

const btnSpouse=(span)=>{
    console.log(DEBUG_ALL,'Spouse')
    if(!span.item?.Spouses||!span.item.Spouses.length){
        app.w2alert('There are no spouses for this person')
        return
    }
    app.treeRoot=span.item.Spouses;
    app.setURLSearchParam('uid',app.treeRoot[0])
    redrawTree()
}

const btnShare=async (span)=>{
    if(!app.shareWith){
        w2alert('First select a user email  in settings to share an item.','Information')
        .then(t=>{
            setTimeout(()=>{
                t.owner.close()
            }
            ,1000)})
        return
    }
    sharePersons(await db.where('id').equals(span.item.id).first(),
    {email:app.shareWith,name:`sharedBy${app.dexieDB.cloud.currentUserId}`})
}

const btnCopy=(span)=>{}
const btnOwn=(span)=>{}
const btnTree=(span)=>{}
const btnFollowDup=(span)=>{
    let uid=span.item.uid
    let doOn=-1
    let allDuplicates=Array.from(query('.duplicate[data-uid="'+uid+'"]'))
    allDuplicates.forEach((duplicate, i)=>{
        if(duplicate==span)doOn=i+1
    })
    if(doOn>=allDuplicates.length)doOn=0
    if(doOn>-1){
        //allDuplicates[doOn].classList.add('selected')
        allDuplicates[doOn].focus()
        allDuplicates[doOn].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
}
const btnMoveDup=(span)=>{
    let uid=span.item.uid
    let oldSpan=span
    let expandedDupSpan, expandedDupSpans=query('span[data-uid="'+uid+'"]:not(.dup-truncated)')
    if(expandedDupSpans.length){
        expandedDupSpan=expandedDupSpans[0].parentElement.cloneNode(true)
        span.parentElement.replaceChild(expandedDupSpan,span)
        query('span.tf-nc',expandedDupSpan).each((r,i)=>{
            //toDo copy items into spans
            app.addToolTipIfNone(r,i)
        })
    }
}

const listOwnedItems=async (items)=>{
    if(!app.ownedGrid){
        let thisGrid=new w2grid({
            name: 'owned-items',
            box: '#content',
            style:"width:100%; height: 100%;",
            recordHeight : 42, //18,

            toolbar:{ tooltip: 'owned Items tooltip', style:"height:40px;",
                items: [
                    {type: 'label', id: 'label', text: 'Persons:', tooltip: 'Items linked to your username/email and shared with you', style:'background-color:lightcyan'},
                    //{ type: 'button', id: 'tree-btn', text: 'Tree', tooltip: 'View tree', onClick:replaceTree},
                    
                    { type: 'button', id: 'goto-btn', text: 'View', tooltip: `View selected item/s as root in tree`, onClick: function (event) {gotoItem(this)},},
                    { type: 'button', id: 'delete-btn', text: 'Delete', tooltip: 'Delete selected item/s', onClick: function (event) {deleteItems(this)},},
                    /*
                    { type: 'button', id: 'compare-btn', text: 'Compare', tooltip: 'Compare item/s', onClick: function (event) {compareItems(this)},},
                    //{ type: 'button', id: 'manage-share-btn', text: 'Manage Sharing', tooltip: 'Manage Sharing item/s', onClick: function (event) {},},
                    { type: 'button', id: 'export-btn', text: 'Export', tooltip: 'Export items',onClick: function(event){ExportItems(this)}},
                    { type: 'button', id: 'share-btn', text: 'Share Item', tooltip: 'Shared item/s', onClick: function (event) {shareItems(this)},},
                    { type: 'button', id: 'get-shared-btn', text: 'Get Shared', tooltip: 'Get shared item/s', onClick: function (event) {},},
                    { type: 'button', id: 'filter-btn', text: 'filter', tooltip: 'filter shared item/s', onClick: function (event) {},},
                    { type: 'button', id: 'set-root-btn', text: 'Set root', tooltip: 'Set root item/s - tree starts from this item', onClick: function (event) {},},*/
                ],
                onClick(event) {
                    if (event.target == 'add') {
                        let recid = grid.records.length + 1
                        this.owner.add({ recid });
                        this.owner.scrollIntoView(recid);
                        this.owner.editField(recid, 1)
                    }
                    if (event.target == 'showChanges') {
                        console.log(DEBUG_ALL,`showChanged`)
                    }
                }
            },

            show: {
                toolbar: true,
                footer: true,
                //toolbarAdd: true,
                //toolbarDelete: true,
                //toolbarSave: true,
                //toolbarEdit: true,
                toolbarSearch:true,
                toolbarReload:true,
            },

            reorderColumns: true,
            multiSearch: false,
            searches: [
                { field: 'uid', label: 'UID ', type: 'text' },
                { field: 'Name', label: 'Name', type: 'text', selected:true },
                { field: 'Label', label: 'Label', type: 'text'},
                //{ field: 'sdate', label: 'Start Date', type: 'date' },
            ],

            columns: [
                { field: 'id', text: 'id', size: '280px', hidden:true, sortable: true, },
                { field: 'uid', text: 'uid', size: '280px', hidden:true, sortable: true, info:{
                    render: (rec, ind, col_ind) => {return '<pre>id:'+rec.id+'</pre><pre>realmId:'+rec.realmId+'</pre><pre>owner:'+rec.owner+'</pre>'}
                    },},
                { field: 'Name', text: 'Name', size: '300px', sortable: true, editable: { type: 'text' }},// sortable: true, resizable: true, render: 'text', editable: { type: 'text' }},
                { field: 'Label', text: 'Label', size: '100%', sortable: true, editable: { type: 'text' }, resizable: true, },

                { field: 'Parents', text: 'Parents', size: '100px', hidden:true, sortable: true },
                { field: 'Children', text: 'Children', size: '100px', hidden:true, sortable: true },
                { field: 'Spouses', text: 'Spouses', size: '100px', hidden:true, sortable: true },
                { field: 'Others', text: 'Others', size: '100px', hidden:true, sortable: true },

                { field: 'Born', text: 'Born', size: '100px', hidden:true, sortable: true },
                { field: 'Died', text: 'Died', size: '100px', hidden:true, sortable: true },
                { field: 'AncestorOf', text: 'AncestorOf', size: '100px', hidden:true, sortable: true },
                //{ field: 'priority', text: 'Priority', size: '80px', attr: 'align="center"' }
            ],

            onSave: function (event) {
                w2alert('save grid');
            },

            onSearch: function (target, data) {
                data.detail.searchData.forEach(s=>{
                    s.operator = 'contains';//candidate for settings?
                })
             }
        });
        thisGrid.on('*', (event) => {
            //console.log(DEBUG_ALL,event)
            if(event.type=='change'){
            let changes=thisGrid.getChanges()
            changes.forEach(change=>{
                let item=thisGrid.records[change.recid]
                let o=item
                if(o?.recid)delete o.recid
                if(o?.w2ui)delete o.w2ui
                Object.keys(change).forEach(key=>{
                    switch(key){
                    case 'recid':o.uid=item.uid;break;
                    default:o[key]=change[key];break;
                    }
                })
                updateUid(o)
            })
            }
        })
        buildLeftToolbar('owned-grid')
        thisGrid.last.field = 'Name'//name of last field searched
        thisGrid.last.search = ''
        app.ownedGrid=thisGrid
    }
}

const ExportItems=async (w2Toolbar)=>{
    let selected=w2Toolbar.owner.getSelection()
    let selection=[]
    let currentUser=app.dexieDB.cloud.currentUserId//document.getElementById('logged-in-user').value//or get it from dexie??
    let str=''
    await Promise.all(selected.map(recn=>{
        //str?str+='\n':str;
        let uid=w2Toolbar.owner.records[recn].uid
        return db.where('uid').equals(uid).toArray(items=>{
            let oldId
            console.log(DEBUG_ALL,`There are ${items.length} items:`)
            ///str?str+='\n':str;
            ///str=str+`There are ${items.length} items:\n`
            if(items.length==1){
                let item=items[0]
                //if(!item.owner||item?.owner==currentUser){//Public or owned
                if(true){
                    if(item?.actions||item.actions===null)delete item.actions;
                    if(item?.hidden||item.hidden===null)delete item.hiddden;
                    if(item?.truncate||item.truncate===null)delete item.truncate;
                    if(item?.owner||item.owner===null)delete item.owner;
                    if(item?.depth||item.depth===null)delete item.depth;
                    if(item?.urls||item.urls===null)delete item.urls;
                    oldId=item.id
                    delete item.id
                    item.realmId='rlm-public'
                    if(!item?.Label)
                        item.Label='';
                    else
                        if(item?.Label)item.Label=item.Label.replaceAll('<br>',' ');
                    let o={}
                    if(oldId){
                        o[oldId]=item
                        console.log(DEBUG_ALL,JSON.stringify(o).replace(/^{/,'').replace(/}$/,','))
                        str=str+JSON.stringify(o).replace(/^{/,'').replace(/}$/,',')
                    }else{
                        console.log(DEBUG_ALL,'oldId not defined')
                        str=str+'\noldId not defined '
                    }
                }
            }else{
                if(items.length>2){
                    console.log(DEBUG_ALL,'error items.length='+items.length)
                    //str+='\nerror items.length='+items.length+' '
                }
                items.forEach(item=>{if(item?.realmId=='rlm-public'){oldId=item.id}})
                items.forEach(item=>{
                    //if(item?.owner==currentUser){
                    if(true){
                        if(item?.actions||item.actions===null)delete item.actions;
                        if(item?.hidden||item.hidden===null)delete item.hiddden;
                        if(item?.truncate||item.truncate===null)delete item.truncate;
                        if(item?.owner||item.owner===null)delete item.owner;
                        if(item?.depth||item.depth===null)delete item.depth;
                        if(item?.urls||item.urls===null)delete item.urls;
                        delete item.id
                        item.realmId='rlm-public'
                        if(item?.Label)item.Label=item.Label.replaceAll('<br>',' ')
                        let o={}
                        if(oldId){
                            o[oldId]=item
                            console.log(DEBUG_ALL,JSON.stringify(o).replace(/^{/,'').replace(/}$/,','))
                            str+=JSON.stringify(o).replace(/^{/,'').replace(/}$/,',')
                        }else{
                            console.log(DEBUG_ALL,'oldId not defined')
                            str+='oldId not defined'
                        }
                    }
                })
            }
        })
    }))
    .finally(f=>{
        if(!str){
            str='No changes to export!'
        }else{
            w2confirm({
                msg: str.replace(/,$/,''),
                title: 'Changed items to Export',
                width: 450,        // width of the dialog
                height: 420,       // height of the dialog
                btn_yes: {
                    text: 'Ok',   // text for yes button (or yes_text)
                    class: '',     // class for yes button (or yes_class)
                    style: '',     // style for yes button (or yes_style)
                    onClick: null  // callBack for yes button (or yes_callBack)
                },
                btn_no: {
                    text: 'No',    // text for no button (or no_text)
                    class: '',     // class for no button (or no_class)
                    style: 'display:none',     // style for no button (or no_style)
                    onClick: null  // callBack for no button (or no_callBack)
                },
                callBack: null,    // common callBack
                onOpen: null,      // event when popup is opened
                onClose: null      // event when popup is closed
            })
        }
    })
}

const gotoItem=(w2Toolbar)=>{
    console.log('gotoItem')
    let selected=w2Toolbar.owner.getSelection()
    let selection=[]
    selected.forEach(rec=>{
        if(rec){
        let uid=w2Toolbar.owner.records[rec].uid
        selection.push(uid)
        app.setURLSearchParam('uid',uid)
        }
    })
    app.replaceTree(selection).then(t=>{
        w2Toolbar.tooltipHide()
    })
}

const updateAllParentReferences=async(uid)=>{
    let children=await db.toArray(all=>all.filter(f=>f.Parents.indexOf(uid)>-1))
    console.log(DEBUG_ALL,`***app?.updateAllParentReferences is ${app?.updateAllParentReferences?'true':'false'}. Delete child references to ${uid}. These are ${JSON.stringify(children)}`)
    if(app?.updateAllParentReferences)
    children.forEach(child=>{
        const index = child.Parents.indexOf(uid);
        if (index > -1){
            child.Parents.splice(index, 1);
            updateUid(child)
        }
    });
}

const deleteItem=async(item)=>{// make into a transaction
    let uid=item.uid
    let sameUid=await db.where('uid').equals(uid).toArray(all=>all.filter(f=>f.id!=item.id))
    if(!sameUid?.length)updateAllParentReferences(uid)//delete reference to this items uid in all child.Parents if it is the only one
    if(item?.id)//its not if its not found
        return db.delete(item.id)
                .then(async d=>{
                    console.log(DEBUG_ALL,`deleted ${item.id}`)
                    if(app.persons){
                        item=await app.load19e87d05(item, true)
                    }
                    afterUpdate(item)
                })
                .catch(err=>console.log(DEBUG_ALL,err))
}

const deleteItems=async(w2Toolbar)=>{
    console.log(DEBUG_ALL,'deleteItems')
    let selected=w2Toolbar.owner.getSelection()
    let selection=[]
    selected.forEach(rec=>{selection.push(w2Toolbar.owner.records[rec])})
    Promise.all(selection.map(async item=>deleteItem(item)))
    .finally(async f=>{
        updateOwnedGrid()
    })
}

const shareItems=(w2Toolbar)=>{
    console.log(DEBUG_ALL,'shareItems')
    let currentUser=app.dexieDB.cloud.currentUserId//document.getElementById('logged-in-user').value//or get it from dexie??
    let selected=w2Toolbar.owner.getSelection()
    let selection=[]
    selected.forEach(rec=>{selection.push(w2Toolbar.owner.records[rec].id)})
    selection.forEach(async id=>{
        sharePersons(await db.where('id').equals(id).first(),{email:'user2@demo.local',name:`sharedBy${currentUser}`})
    })
}

const compareItems=(w2Toolbar)=>{
    console.log(DEBUG_ALL,'compareItems')
    let selected=w2Toolbar.owner.getSelection()
    let selection=[]
    selected.forEach(rec=>{selection.push(w2Toolbar.owner.records[rec].uid)})
    selection.forEach(async uid=>{
        let items=await db.where('uid').equals(uid).toArray()
        let str=''
        if(items.length==2){
            items=items.map(item=>!('Label' in item)?(item.Label='',item):item)//add the 'Label' key if missing
            let urls=items.map(item=>app.getAllUrls(item.Label))//extract urls for separate comparison
            items=items.map(item=>(item.Label=item.Label.replaceAll('<br>',' ').replaceAll(/\n/igm,' ').replaceAll(/\s{2,}/igm,' '),item.Label=app.removeAllUrls(item.Label),item))
            let labels=items.map(item=>item.Label)
            
            if(items[0].Name!=items[1].Name){
                str+=`[${items[0].Name}]\n[${items[1].Name}]\n`
            }
            
            if(labels[0]!=labels[1]){
                str+=labels[0]+'\n'+labels[1]+'\n'
            }

            if(urls[0]?.length!=urls[1]?.length){
                str+=JSON.stringify(urls[0])+'\n'+JSON.stringify(urls[1])+'\n'
            }
            
            if(JSON.stringify(items[0].Parents)!=JSON.stringify(items[1].Parents)){
                str+=`[${JSON.stringify(items[0].Parents)}]\n[${JSON.stringify(items[1].Parents)}]\n`
            }
            
            if(!str.length){
                console.log(DEBUG_ALL,`Item  ${items[0].Name} is the same for both copies`)
            }
        }else{
            //console.log(DEBUG_ALL,`There are ${items.length} records for ${uid} ${items[0].Name}`)
        }
        if(str){
            str=`${items[0].id} and ${items[1].id} (uid:'${items[0].uid}'  '${items[0].Name}')\n`+str
            console.log(DEBUG_ALL,str)
        }
    })
}

const deselectOtherButtons=(btn)=>{
    let btnState=tbleft.get(btn).checked
    tbleft.uncheck('btn-select')
    tbleft.uncheck('btn-dom')
    tbleft.uncheck('btn-edit')
    tbleft.uncheck('btn-copy')
    tbleft.uncheck('btn-delete')
    tbleft.uncheck('btn-share')
    tbleft.uncheck('btn-unlock')
    if(btnState)tbleft.check(btn);
    else tbleft.uncheck(btn);
}

var tbleft = w2ui['layout'].get('left').toolbar
tbleft.on('click',(ev,toolbar)=>{
    console.log(DEBUG_ALL,'left toolbar click')
    toolbar.isCancelled=true
    switch(ev){
        case 'home-btn':
            //toolbar.isCancelled=true
            //app.treeRoot=['00000000-0000-0000-0000-000000000000']//'00000000-0000-0000-0000-000000000000','19e87d05-6534-4167-8cb4-86f467a1e4f2'
            app.layout.html('main',placeTreeDiv().outerHTML)
            redrawTree()
        break;
        case 'btn-own-grid':
            //toolbar.isCancelled=true
            updateOwnedGrid();
        break;
        case 'btn-start-tree':
            
        
        break;
        case'btn-add-parents':break;
        case 'btn-add-children':break;
        case 'btn-untruncate':
        let spans=Array.from(query('.selected'))
        spans.forEach(span=>{
            let parentUl=span.parentElement.querySelector('ul')
            //while(parentUl.firstElementChild){parentUl.firstElementChild.remove()}
            if(parentUl)parentUl.remove()
            let parentLi=span.parentElement
            //parentLi.remove()
            app.show(parentLi, span.item.Parents)
        })
        break;
        case 'nav-or-edit':
            deselectOtherButtons(ev);break
        case 'btn-select':
            deselectOtherButtons(ev);break
        case 'btn-edit':
            deselectOtherButtons(ev);break            
        case'btn-copy':
            deselectOtherButtons(ev);break
        case 'btn-dom':
            deselectOtherButtons(ev);break
        case 'btn-delete':
            deselectOtherButtons(ev);break  
        case 'btn-share':
            deselectOtherButtons(ev);break 
        case 'btn-unlock':
            deselectOtherButtons(ev);
            app.unlockChecked=tbleft.get(ev).checked?false:true;
            break;
        case 'btn-focus':
            let currentlySelected, allSelected=query('.selected')
            if(allSelected.length>0){
                currentlySelected=allSelected[0]
            }else{
                w2ui.layout.get('left').toolbar.hide('btn-focus')
            }
            currentlySelected.classList.remove('selected')
            let uid=currentlySelected.dataset.uid
            let doOn=-1
            let allDuplicates=Array.from(query('.duplicate[data-uid="'+uid+'"]'))

            allDuplicates.forEach((duplicate, i)=>{
                if(duplicate==currentlySelected)doOn=i+1
                })
            if(doOn>=allDuplicates.length)doOn=0
            if(doOn>-1){
                //allDuplicates[doOn].classList.add('selected')
                allDuplicates[doOn].focus()
                allDuplicates[doOn].scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
            }
            break;
            case 'btn-new': addNewItem()
            break;
            case 'btn-include-public-grid':
                app.includePublic=toolbar.detail.item?.checked?false:true
                //toolbar.isCancelled=true
                updateOwnedGrid()
            break;
            case 'menu-show-buttons':toolbar.isCancelled=false;break;
            case 'menu-show-buttons:btn-show-id':app.ownedGrid.toggleColumn('id');break;
            case 'menu-show-buttons:btn-show-uid':app.ownedGrid.toggleColumn('uid');break;
            case 'menu-show-buttons:btn-show-name':app.ownedGrid.toggleColumn('Name');break;
            case 'menu-show-buttons:btn-show-label':app.ownedGrid.toggleColumn('Label');break;
            case 'menu-show-buttons:btn-show-parents':app.ownedGrid.toggleColumn('Parents');break;
            case 'menu-show-buttons:btn-show-children':app.ownedGrid.toggleColumn('Children');break;
            case 'menu-show-buttons:btn-show-spouses':app.ownedGrid.toggleColumn('Spouses');break;
            case 'menu-show-buttons:btn-show-others':app.ownedGrid.toggleColumn('Other');break;
            case 'menu-show-buttons:btn-show-born':app.ownedGrid.toggleColumn('Born');break;
            case 'menu-show-buttons:btn-show-died':app.ownedGrid.toggleColumn('Died');break;
            case 'menu-show-buttons:btn-show-ancestor':app.ownedGrid.toggleColumn('AncestorOf');break;
            default:console.log(DEBUG_ALL,'left toolbar not handled');break;
    }
})    

window.tbtop = w2ui['layout'].get('top').toolbar
tbtop.on('*',(ev,toolbar)=>{//all events
    //console.log(DEBUG_ALL,'top toolbar event '+toolbar.type+' '+ev)
    if(['mouseenter','mouseleave','resize'].indexOf(toolbar.type)>-1){
    //}else if(['refresh'].indexOf(toolbar.type)>-1 && ev=='user-input'){
    }else if(ev=='user-input'||ev=='logged-in-user'){
        //console.log(toolbar.type+' '+ev)
        /*
        query('#logged-in-user').each(elem=>{
            //elem.addEventListener("mousedown",ev=>ev.preventDefault())
            //elem.addEventListener("keydown",ev=>ev.preventDefault())
            elem.addEventListener("change",ev=>{
                console.log('change')
            })
    })*/
    }else if(toolbar.type=='change'){
    switch(ev){
        case 'user-input':
            //console.log(DEBUG_ALL,'changed user-input')
            app.openUserManager('')
        break;    
        case 'depth-range':
            app.maxDepth=parseInt(toolbar.detail.value)
            if(app.maxDepth>9)app.maxDepth=0
            tbtop.set('depth-label', { count: app.maxDepth?app.maxDepth:'All'})
            let o={key:'maxDepth',value:app.maxDepth}
            if(dbs?.maxDepth)o.id=dbs.maxDepth;
                dbs.put(o).then(id=>dbs.maxDepth=id)
                .finally(f=>{
                    //app.settingsForm.record.check=app.checkChildrenCount
                    //app.settingsForm.refresh()
                })
            //app.layout.html('main',placeTreeDiv().outerHTML)
            redrawTree()
            break;
        case 'scale-range':
            let map=['1.5','1','.95','.9','.8','.7','.6','.5,.6','.3,.6','.1,.6']
            let value=map[parseInt(toolbar.detail.value)-1]
            app.currentScale=`scale(${value})`
            tbtop.set('scale-label', { count: value})
            let scalingWrapper=document.getElementById('scalingWrapper')
            if(scalingWrapper)scalingWrapper.style.transform=app.currentScale
            break;
            case 'search-input':
                console.log(DEBUG_ALL,'search change')    
                findPersons(toolbar.detail.value,'change')
            break;
            default:
                console.log(DEBUG_ALL,'unhandled change '+ev)
            break;
    }
    } else if(toolbar.type=='click'){    
        switch(ev){
        case 'help-btn':
            window.open('./treeViewHelp.html', '_blank');
            break;    
        case 'file-btn':
            break;
        case 'file-btn:start-btn':
            app.treeRoot=['00000000-0000-0000-0000-000000000000']//'00000000-0000-0000-0000-000000000000','19e87d05-6534-4167-8cb4-86f467a1e4f2'
            app.layout.html('main',placeTreeDiv().outerHTML)   
            redrawTree()
        break;
        case 'children-mustbe-ancestors':
            app.onlyAncestors=toolbar.detail.item.checked
        break;
        case 'home-btn':
            app.treeRoot=['00000000-0000-0000-0000-000000000000']//'00000000-0000-0000-0000-000000000000','19e87d05-6534-4167-8cb4-86f467a1e4f2'
            app.layout.html('main',placeTreeDiv().outerHTML)
            redrawTree()
        break;
        case 'file-btn:save-btn':
            app.downloadArchive(app.dexieDB.cloud.currentUserId)
        break;
        case 'file-import':
            console.log(DEBUG_ALL,'file-import')
            app.openImport()
        break;
        case 'public-import':
            console.log(DEBUG_ALL,'public-import')
            app.openPublicImport()
        break;
        case 'file-btn:list-public-btn':
            listPublicItems()
        break;
        case 'file-btn:settings-btn':
            openSettings()
        break;
        case 'file-btn:list-btn':
            updateOwnedGrid()
        break;    
        case 'user-management':
            console.log(DEBUG_ALL,'user-management')
            app.openUserManager('')
        break;
        case 'user-input':
            console.log(DEBUG_ALL,'user-input')
        break;
        case 'btn-dom':
        break;
        case 'btn-edit':
            console.log(DEBUG_ALL,'btn-edit')
        break;
        case '':
        break;
        case 'search-input':
        case 'search-button':
            console.log(DEBUG_ALL,'search click')    
            //findPersons(toolbar.detail.value,'click')
            findPersons(tbtop.get('search-input').value,'click')
        break;
        case 'colour-choice':break;
        case 'colour-choice:item1':updateColorKey('sex');break
        case 'colour-choice:item2':updateColorKey('cob');break
        case 'colour-choice:item3':updateColorKey('descendant');break;
        case 'colour-choice:item4':updateColorKey('source');break
        default:
            console.log(DEBUG_ALL,'unhandled click '+ev)    
        }  
    }
    })

const updateColorKey=(colourKey)=>{
    app.colourKey=colourKey
    let o={key:'colourKey',value:app.colourKey}
    if(dbs?.colourKey)o.id=dbs.colourKey;
    dbs.put(o).then(id=>dbs.colourKey=id)
    let cs=query('.tf-tree').get()
    if(cs.length){
        cs[0].classList.remove('sex','cob','descendant','source')
        cs[0].classList.add(colourKey)
    }
}

const buildOwnedGridToolbar=()=>{
    let itemsA=[
        /*{"type":"button","id":"w2ui-reload","icon":"w2ui-icon-reload","tooltip":"Reload data in the list","line":1,"text":""},
        {"id":"w2ui-search","type":"html","html":`
    <div class="w2ui-grid-search-input">
        <div class="w2ui-icon w2ui-icon-search w2ui-search-down w2ui-action" data-click="searchShowFields"></div>
        <div id="grid_owned-items_search_name" class="w2ui-grid-search-name">
            <span class="name-icon w2ui-icon-search"></span>
            <span class="name-text"></span>
            <span class="name-cross w2ui-action" data-click="searchReset">x</span>
        </div>
        <input type="text" id="grid_owned-items_search_all" class="w2ui-search-all" tabindex="-1" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" 
        placeholder="" value="" data-focus="searchSuggest" data-click="stop">
        <div class="w2ui-search-drop w2ui-action" data-click="searchOpen" style="display: none" >
            <span class="w2ui-icon-drop"></span>
        </div>
    </div>`,
    "line":1,"text":"","tooltip":""},*/
        {type: 'label', id: 'label', text: 'Persons:', tooltip: 'Items linked to your username/email and shared with you', style:'background-color:lightcyan'},
        //{ type: 'button', id: 'tree-btn', text: 'Tree', tooltip: 'View tree', onClick:replaceTree},
    
        { type: 'button', id: 'goto-btn', text: 'View', tooltip: `View selected item/s as root in tree`, onClick: function (event) {gotoItem(this)},},
        { type: 'button', id: 'delete-btn', text: 'Delete', tooltip: 'Delete selected item/s', onClick: function (event) {deleteItems(this)},},
        ]
    let itemsB=[
        { type: 'button', id: 'compare-btn', text: 'Compare', tooltip: 'Compare item/s', onClick: function (event) {compareItems(this)},},
        //{ type: 'button', id: 'manage-share-btn', text: 'Manage Sharing', tooltip: 'Manage Sharing item/s', onClick: function (event) {},},
        { type: 'button', id: 'export-btn', text: 'Export', tooltip: 'Export items',onClick: function(event){ExportItems(this)}},
        { type: 'button', id: 'share-btn', text: 'Share Item', tooltip: 'Shared item/s', onClick: function (event) {shareItems(this)},},
        { type: 'button', id: 'get-shared-btn', text: 'Get Shared', tooltip: 'Get shared item/s', onClick: function (event) {},},
        { type: 'button', id: 'filter-btn', text: 'filter', tooltip: 'filter shared item/s', onClick: function (event) {},},
        { type: 'button', id: 'set-root-btn', text: 'Set root', tooltip: 'Set root item/s - tree starts from this item', onClick: function (event) {},},
        ]
    //app.ownedGrid.toolbar.items=app?.showExtra?[...itemsA,...itemsB]:itemsA;
    //app.ownedGrid.toolbar.tooltip = 'top';
    //app.ownedGrid.multiSearch = true;
    //app.ownedGrid.refresh();
}

const updateOwnedGrid=(searchTerm)=>{
    if(!app.ownedGrid)listOwnedItems()
    buildTopToolbar('owned-grid')
    buildLeftToolbar('owned-grid')
    buildOwnedGridToolbar()
    if(app?.persons){
        app.ownedGrid.records=[...app.ownedGrid.records,...app.persons]
        app.ownedGrid.records=app.ownedGrid.records.map((m,i)=>(m.recid=i,m))
    }
    db.toArray(all=>{
        if(app?.persons){
            all=[...all,...app.persons]
        }
        //.filter(f=>(includePublic||f.realmId!='rlm-public')&&(!searchFilter?.length||(searchFilter.indexOf(f.uid)>-1)))
        app.ownedGrid.records=all.filter(f=>(app.includePublic||f.realmId!='rlm-public'||f.realmId=='unauthorized'))
        .map((m,i)=>{
                if(app.colourKey=='sex'){
                    if(m.sex==1)m.w2ui={class:"male"}
                    if(m.sex==2)m.w2ui={class:"female"}
                }
                if(m.realmId=='unauthorized')m.w2ui={class:"unauthorized"}
                if(m.realmId=='rlm-public')m.w2ui={class:"rlm-public"}
                if(m.realmId=='server-json')m.w2ui={class:"server-json"}
                m.recid=i;
                return m
                });
        app.ownedGrid.refresh()
        if(!app.ownedGrid.records.length&&!app.includePublic){app.w2alert('you have no private data. Include public data?','information !')
            .then(t=>{
                setTimeout(()=>{
                    t.owner.close()
                }
                ,1000)})}
        if(searchTerm?.length)app.ownedGrid.last.search=searchTerm
        w2ui.layout.html('main',app.ownedGrid)
        allUnauthorized=app.ownedGrid.records.filter(f=>f.realmId=='unauthorized')
        if(allUnauthorized.length){
            w2alert(`You are not logged-in! Therefore any changes will not be permanent. Log in to be able to store your items privately to the cloud. You currently have ${allUnauthorized.length} such changes.`)
        }
    })
}
    
const getChildrenFromUids=async(allChildrenUids)=>{
    return await Promise.all(allChildrenUids.map(async childId=>
            await app.dexieDB
            .persons.where('uid').equals(childId)
            .toArray(items=>{
                return app.getItem(items, childId)
                //if(!items.length)return {uid:childId, Name:'not found', Label:'Child not found'}
                //if(items.length==1)return items[0];
                //items=items.filter(f=>f.realmId!='rlm-public')
                //if(items.length)return items[0];
            })
    ))
}

const navChildren=(target, check)=>{
    //is event.target.parentElement a firstLevelSpan #treediv ul [li] any-span
    let shownChildsSpan=target.parentElement
    if(shownChildsSpan.tagName!='SPAN'){console.log(DEBUG_ALL,'shownChildsSpan is not a span');return}
    //let firstLevelSpan=Array.from(app.query('#treediv>ul>li>span'))//may also need to deal with multiple spans
    let item=shownChildsSpan?.item
    if(!item){console.log(DEBUG_ALL,'no item for event.target');return}

    let allChildrenUids
    if(!check){
        allChildrenUids=item?.Children
    }else{
        allChildrenUids=item.linkedChildren.map(m=>m.uid)
    }
    if(!allChildrenUids||typeof allChildrenUids!='object' || !allChildrenUids.length){
        console.log(DEBUG_ALL,'no valid children');return
    }
    //add children spans to shown childs li
    let shownChildsUl=shownChildsSpan.parentElement.parentElement.parentElement.parentElement//might be tree-div at top
    //                    span              li           ul        li/tf-tree        ul    
    let shownChildsLi=shownChildsSpan.parentElement.parentElement.parentElement
    //                    span              li           ul            li/tf-tree

    if(shownChildsLi.classList.contains('tf-tree')){
        let ul, li, tmpSpan, shownUl=shownChildsSpan.parentElement.parentElement
        shownChildsLi.prepend(ul=document.createElement('UL'))
        ul.prepend(li=document.createElement('LI'))
        li.append(tmpSpan=document.createElement('SPAN'))
        li.append(shownUl)
        tmpSpan.item={uid:''}
        shownChildsLi=li
       }

    //rather look for direct spans of li and remove them all 
    //direct children SPAN's under li, i.e. not looking for child<span> after any UL's
    let childSpans=getUrlsFirstLevelChildSpans(shownChildsLi)
    
    app.childsCob=shownChildsSpan.dataset.cob//for all children same as ancestor sibling
 
    if(childSpans.length>1){
        childSpans.forEach((span,i)=>{
            if(i)span.remove()  //existing shownChild
        })
    }else if(childSpans.length==1){
        childSpans.forEach((span,i)=>{//there is one unless other children have been added
    
            //insert other children after shown child
            if(!i){
                if(app.onlyAncestors){
                    getChildrenFromUids(allChildrenUids).then(childrenItems=>{
                        addChildrenToTree(span, shownChildsLi, childrenItems.filter(f=>f?.AncestorOf?f.AncestorOf.indexOf('19e87d05-6534-4167-8cb4-86f467a1e4f2')>-1:false))
                    })
                }else{// Display all children
                    getChildrenFromUids(allChildrenUids).then(childrenItems=>{
                        addChildrenToTree(span, shownChildsLi, childrenItems)
                    })
                }    
            }
        })
    }
}

const getUrlsFirstLevelChildSpans=(shownChildsLi)=>{
    let children=Array.from(shownChildsLi.children)
    let spans=children.filter(f=>f.nodeName=='SPAN')
    return spans
}

const addChildrenToTree=(span, shownChildsLi, childrenItems)=>{
    let existingUID=span.item.uid
    childrenItems.forEach((childItem,i) => {
        if(childItem.uid!=existingUID){//don't redraw existing child
            let childSpan=app.buildContentSpan(shownChildsLi, childItem)
            //childSpan.classList.remove('tf-nc');
            childSpan.classList.add('child','tf-nc')
            //if(childrenItems.length==i+1)childSpan.classList.add('tf-nc');    
            span=span.insertAdjacentElement('afterend',childSpan);
        }
    });
}

function arraysEqual(a, b) {
    // Check if the arrays are identical
    if (a === b) return true;
    
    // Check if either array is null
    if (a == null || b == null) return false;
    
    // Check if the lengths are different
    if (a.length !== b.length) return false;

    // Clone the arrays to avoid modifying the originals
    let aCopy = [...a];
    let bCopy = [...b];

    // Sort both arrays
    aCopy.sort();
    bCopy.sort();

    // Compare sorted arrays
    for (let i = 0; i < aCopy.length; ++i) {
        if (aCopy[i] !== bCopy[i]) return false;
    }
    
    return true;
}

const updateSpan=(span, item)=>{
    if(!arraysEqual(item.Parents,span.item.Parents)){//The Parents array has changed so rebuild from here
        let parentOfli=span.parentElement.parentElement//this is the ul
        let indexOfChild=Array.prototype.indexOf.call(parentOfli.children, parentOfli);
        span.remove()
        //app.show(parentOfli, [li.firstElementChild.item.uid],indexOfChild)
        
        app.show(parentOfli, [item.uid], indexOfChild)
    }else{
        span.item=item
        span.textContent=item.Name
        app.adornSpan(span,item)
        if(item?.truncate){
            console.log(DEBUG_ALL,'truncate')
            span.parentElement.remove()
        }
    }
}

const unlock=(span)=>{
    console.log(DEBUG_ALL,'unlock')
    let exists=app.data.find(f=>f.uid==span.item.uid)
    if(exists){
        //span.item=exists
        if(!exists?.Parents){
            try{
                exists.Parents=[]
                if(exists?.arrOfParents){exists.Parents=exists.arrOfParents;delete exists.arrOfParents;}
                if(exists?.arrOfChildren){exists.Children=exists.arrOfChildren;delete exists.arrOfChildren;}
                if(exists?.arrOfSpouses){exists.Spouses=exists.arrOfSpouses;delete exists.arrOfSpouses;}
                if(exists?.arrOfOthers){exists.Others=exists.arrOfOthers;delete exists.arrOfOthers;}
                
                if(!exists?.Parents)exists.Parents=[]
                if(!exists?.Children)exists.Children=[]
                
                if(typeof exists?.Parents=='string')exists.Parents=JSON.parse(exists.Parents)
                if(typeof exists?.Children=='string')exists.Children=JSON.parse(exists.Children)
                if(typeof exists?.Spouses=='string')exists.Spouses=JSON.parse(exists.Spouses)
                if(typeof exists?.Others=='string')exists.Others=JSON.parse(exists.Others)
                
                updateUid(exists).then(t=>{updateSpan(span,exists)})
            }catch(err){
                console.log(DEBUG_ALL,err.message)
            }
        }
    }
}

const devHackNoLayout=()=>{
    let maxDepth,currentScale
    //if(!document.getElementById('max-depth')){
    //    document.body.appendChild(maxDepth=document.createElement('input'));
    //    maxDepth.type='text';
    //    maxDepth.id='max-depth';
    //    maxDepth.setAttribute('value','1')
    //}
    //if(!document.getElementById('current-scale')){
    //    document.body.appendChild(currentScale=document.createElement('input'));
    //    currentScale.id='current-scale'
    //    currentScale.style.transform='scale(1.0)'
    //}
    if(document.getElementById('triggerit'))document.getElementById('triggerit').remove()
    document.body.appendChild(placeTreeDiv())
    document.getElementById('triggerit').style.position='absolute';
    document.getElementById('triggerit').style.top='0';
}

app.openImport=()=>{
    w2popup.open({
        title   : 'Import Data file',
         body    : '<div id="import" style="position: absolute; width:100%; height:100%; left:0; top:0;"></div>'
    })  
    setupDropHandling('import')
}

const addNewItem=()=>{//run in treeview
    console.log(DEBUG_ALL,`addNewItem`)
    if(!query('.selected').get().length){
        w2alert('Select an item to attach this new item to first','Information')
        .then(t=>{
            setTimeout(()=>{
                t.owner.close()
            }
            ,1000)})
        return
    }
    let newItem=createNewItem()
    let o
    let attachedTo=app.treeRoot[0]// it is the uid
    let selectedItems=query('span.tf-nc.selected').get()
    if(selectedItems.length){
        attachedTo=selectedItems[0].item.uid
    }
    db.put(newItem)
    .then(async id=>{
        console.log(DEBUG_ALL,'put new item id#:',id)
        let realm=app.dexieDB.cloud.currentUserId
        if(realm=='unauthorized')realm='rlm-public'
        let item=(await db.where('uid').equals(attachedTo).toArray(all=>all.filter(f=>f.realmId==realm))).shift()
        if(!item){
            item=(await db.where('uid').equals(attachedTo).toArray(all=>all.filter(f=>f.realmId=='rlm-public'))).shift() 
        }
        if(item){
            let Parents=Array.from(new Set([...item.Parents,...[newItem.uid]]))
            item.Parents=Parents
            updateUid(item).finally(f=>redrawTree())
        }
    })
    .catch(err=>w2alert(`failed to create new item ${err.message}`))
    .finally(f=>{
    })
}

const updateItem=async(item)=>{
    return db.where('id').equals(id).modify(item)
    .then(t=>{
        let message
        if(t){//the number of items modified
            message=`modified: ${JSON.stringify(t)} id: ${id} uid: ${o.uid}`
            console.log(DEBUG_ALL,message)
            //w2alert(message)
        }else{
            message=`modified failed: ${JSON.stringify(t)} id: ${id} uid: ${o.uid}`
            console.log(DEBUG_ALL,message)
            //w2alert(message)
        }
    })
    .catch(async err=>{
        console.log(DEBUG_ALL,`modify error:${err.message} for  id:${id} uid:${o.uid}`)
    })
    .finally(f=>{
        afterUpdate(item)
    })
}

app.openUserManager=(elem)=>{
    if(w2ui.userManagement){
    }else{
        app.userManagement = new w2form({
            //box:'#form',
            name:'userManagement',
            style: 'border: 0px; background-color: transparent;',
            fields: [
                { field: 'userInfoEmailtype', type:'text', 
                    html: { label: 'email username', attr:'style="width:330px"'} },
            ],
            record: {
                userInfoEmailtype :'user1@demo.local',
            },
            actions: {
                Login(event) {
                    console.log(DEBUG_ALL,'login to '+this.record.userInfoEmailtype)
                    app.login(this.record.userInfoEmailtype)
                w2popup.close()
                },
                Logout(event){
                    app.logout()
                    w2popup.close()
                },
                Cancel(event) {
                w2popup.close()
                }
            },
        })
    }
    w2popup.open({
        title   : 'User Management',
        body:`
<p>In order to save your own data or modify public data you will need to log into the database. This can be by done by supplying an email address to which a login code will be sent.</p>
<div id="user-management" xstyle="width: 100%; height: 100%;"></div>
`,
        style   : 'padding: 15px 0px 0px 0px',
        formHTML:`<div>Hello</div>`,
        width   : 500,
        height  : 280,
        showMax : true,
        async onToggle(event) {
            await event.complete
            w2ui.userManagement.resize();
        },
        onClose(event){
            //w2ui.userManagement.???()  
        }
    })
    .then((event) => {
        if(elem)app.userManagement.record.userInfoEmailtype=elem
        w2ui.userManagement.render('#user-management')
    });
    
    w2ui.userManagement.on('*', function (event,c) {
        let ind, rec
        console.log('userManagement')
        switch(event.type){
            default:break;
        }
    })
}

app.createLeftToolbar=()=>{
    if(!app.leftToolbar1)app.leftToolbar1=new app.w2toolbar({
        items:[
            { id:"leftToolbar1", type: 'button', id: 'test-btn', icon: 'fa fa-home home-btn', tooltip:'Goto the home item' },{ type: 'new-line',},
        ]
    })
}

app.leftToolbar=(f)=>{
    app.w2ui.layout.get('left').toolbar.items.forEach(item=>item.hidden=f)
    app.w2ui.layout.get('left').toolbar.refresh()
}

/*******************************************************Export data and Import data */

//JSON.stringify(app.data)
//data.app=JSON.parse()

function setupDropHandling(elId){
	let dropArea = document.getElementById(elId)
	;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
		dropArea.addEventListener(eventName, preventDefaults, false)
	  })
	  function preventDefaults (e) {
		e.preventDefault()
		e.stopPropagation()
	  }
;['dragenter', 'dragover'].forEach(eventName => {
		dropArea.addEventListener(eventName, highlight, false)
	  })
	  
;['dragleave', 'drop'].forEach(eventName => {
		dropArea.addEventListener(eventName, unhighlight, false)
	  })
	  
function highlight(e) {
		dropArea.classList.add('highlight')
	  }
	  
function unhighlight(e) {
		dropArea.classList.remove('highlight')
	  }

dropArea.addEventListener('drop', handleDrop, false)
}      

const handleDrop=(event)=>{
    console.log(DEBUG_ALL,'handle drop')
	let dt = event.dataTransfer
    let files = dt.files
	handleFiles(files)
}

const loadZipFile=(zipped)=>{
    let zip = new JSZip();
    jsZipUtils.getBinaryContent('assets/json/'+file+'.zip',function(err, data){
    zip.loadAsync(data)
		.then(zip=>{
        })
    }) 
}   

const loadJSONFile=(file)=>{
    try{
        showPopup('Reading data from file:',5000)
        app.data=[]
        return file.text()
        .then(data=>{
            let o=JSON.parse(data)
            for (const [key, value] of Object.entries(o['data']['rlm-persons']['persons'])) {
                app.data.push(value)
            }
            //app.data=app.data.map(m=>{
            //    m.uid=m.uid//.replace(/^prs/,'');
            //    return m
            //})    
        })
        .catch(err=>{
            console.log(DEBUG_ALL,'file.text():'+err.message)
        })
        .finally(f=>{
            extractInfo()
            buildAncestorOf('19e87d05-6534-4167-8cb4-86f467a1e4f2')
            markPrivate()//must be after extractInfo
            hidePopup()
            show(app.history[app.histi])
        }) 	

    }catch(err){
        console.log(DEBUG_ALL,'TryCatch:'+err.message)
    }
}

const handleFiles=(files)=>{
    console.log(DEBUG_ALL,'handleFiles');
	//([...files]).forEach(previewFile)
	;([...files]).forEach(f=>{
		//previewFileBlob(f)
		//loadZippedJSONLocalFile(f)
        //loadZippedJSON(f)
        loadJSONFile(f)
	})
}

const findPersons=async (search, typeOfEvent)=>{
    updateOwnedGrid(search)
}

const addList=(elem,list)=>{
    if(typeof list=='string'){
        try{
            list=JSON.parse(list.replaceAll("'",'"'));
            if(typeof list=='string')JSON.parse('["'+list.replaceAll("'",'"')+'"]');
        }catch(e){
            try{
                list=JSON.parse('['+list.replaceAll("'",'"')+']');
            }catch(e){
                console.log(DEBUG_ALL,`Cant parse ${list}`)
            }
        }
    }
    if(typeof list==='object'){
        list.forEach(uid=>{
            let input=elem.appendChild(document.createElement('input'))
            input.value=uid;
            input.onclick=function(ev){
                ev.target.classList.contains('selected')?ev.target.classList.remove('selected'):ev.target.classList.add('selected');
            }
            let found=app.data.find(f=>f.uid==uid)
            if(found){
                input.title=found.Name;
                input.classList.remove('not-in-data')
                if(found.hide)input.classList.add('hidden');
                else input.classList.remove('hidden');
            }else{
                input.title='Name not found in this dataset'
                input.classList.add('not-in-data')
            }
        })
    }
}

/* *************** document click ***************************** */
document.addEventListener("click",event=>{
    if(app.showEvent)console.log(DEBUG_ALL,'click on document')
    if(event.target.classList.contains('tf-nc')&&event.target.dataset.uid=='00000000-0000-0000-0000-000000000000'){
        app.dexieDB.cloud.sync().finally(t=>
        app.replaceTree(['00000000-0000-0000-0000-000000000000']))
    } else if(event.target.classList.contains('tf-nc')&&event.target.dataset.uid=='00000000-0000-0000-0000-000000000001'){
        //w2popup.open({
        //    title:'Help popup',
        //    body:'<div>The help</div>'
        //})
        window.open('./treeViewHelp.html', '_blank');
    }else if(event.target.classList.contains('tf-nc')&&event.target.dataset.uid=='00000000-0000-0000-0000-000000000002'){
        console.log('clicked on 2')//should we go there?
        app.setURLSearchParam('uid',event.target?.item?.uid)//or here
        replaceTree([event.target?.item?.uid]) 
    }else if(event.target.classList.contains('tf-nc')||event.target.classList.contains('child')){//clicked on tree node

        if(app.query('#tb_layout_left_toolbar_item_btn-dom.checked').length){//remove nodes after clicked node
            let span=event.target
            app.query('span.tf-nc').each(s=>{s.style.border=''})
            span.style.border='red solid 3px'
            let childsSpan=span?.parentElement?.parentElement
            let childItem=span?.item
            if(childItem){
                let id=childItem.id
                ,truncate=true
            }
            span.parentElement.remove()
            
            if(!childsSpan.querySelectorAll('.tf-nc').length){
                if(app.showEvent)console.log(DEBUG_ALL,'addTerminatingHasMoreParents ')
                app.addTerminatingHasMoreParents(childsSpan.parentElement.querySelector('span'))
                childsSpan.parentElement.querySelector('ul').remove()
            }
        }else if(app.query('#tb_layout_left_toolbar_item_btn-unlock.checked').length){
            console.log(DEBUG_ALL,'btn-unlock')
            if(!app?.data){//https://erroluys.com/covenantresearch5.html
                app.data=fetch('http://localhost:1234/all_persons_original.json')
                .then(r=>r.json())
                    .then(data=>{
                        app.data=data
                        unlock(event.target)
                    })
            }else{
                unlock(event.target)
            }
        }else if(app.query('#tb_layout_left_toolbar_item_btn-select.checked').length){//select the span and show duplicates menu if applicable
            console.log(DEBUG_ALL,'select this span')
            let elemState=event.target.classList.contains('selected')
            event.target.focus()
            //event.target.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
            app.query('span.tf-nc.selected').each(span=>span.classList.remove('selected'))

            if(!elemState)event.target.classList.add('selected');
            //if selected is a duplicate then enable tab through duplicates
            if(event.target.classList.contains('duplicate')){
                //app.w2ui.layout.set('left:btn-focus',{hidden:false})
                //app.w2ui['layout'].get('left').toolbar.set('btn-focus',{hidden:false})
                //app.w2ui['layout'].get('left').toolbar.get('btn-focus').hidden=false
                app.w2ui.layout.get('left').toolbar.show('btn-focus')
            }else{
                //app.w2ui.layout.set('left:btn-focus',{hidden:true})
                app.w2ui.layout.get('left').toolbar.hide('btn-focus')
            }
            if(event.target.classList.contains('truncated')){
                app.w2ui.layout.get('left').toolbar.hide('btn-untruncate')
            }else{
                app.w2ui.layout.get('left').toolbar.show('btn-untruncate')
            }
        }else if(app.query('#tb_layout_left_toolbar_item_btn-edit.checked').length){//edit item using editor
                openEdit('',event.target.item); return;
        }else if(app.query('#tb_layout_left_toolbar_item_btn-copy.checked').length){//copy an item gaining ownership from rlm-public
            //console.log(DEBUG_ALL,'copy an item gaining ownership from rlm-public')
            let uid=event.target.dataset.uid
            
                app.dexieDB.persons.where('uid').equals(uid)
                .toArray(items=>{
                    let item
                    if(!items.length){
                        console.log(DEBUG_ALL,'could not find any matching items')
                    }else{
                    if(app.dexieDB.cloud.currentUserId=='unauthorized'){
                        app.w2alert('you can\'t copy item if you\'re not logged in')
                    }else{
                        app. dexieDB.persons.delete(uid).then(async d=>{
                            delete item.id//can't be the same globally
                            delete item.owner//automaticially over-ridden
                            delete item.realmId//the important part
                            let nuwe
                            if(d){nuwe=await app.dexieDB.persons.put(item)
                            console.log(DEBUG_ALL,'made the copy:'+JSON.stringify(nuwe))
                }})}}})
            
        }else if(app.query('#tb_layout_left_toolbar_item_btn-delete.checked').length){//delete the item and span
            console.log(DEBUG_ALL,'delete')
        }else if(app.query('#tb_layout_left_toolbar_item_btn-share.checked').length){//share the item with other users
            console.log(DEBUG_ALL,`share`)
        }else{//goto selected item start from span as root
            app.setURLSearchParam('uid',event.target?.item?.uid)
            replaceTree([event.target?.item?.uid]) 
        }
        //end of clicked on tf-nc or child span

    }else if(event.target?.parentElement?.dataset?.click){  //does this make any sense??? All <i> are comming here  
        if(event.target.parentElement.dataset.click.match(/"back"/)){
            app.back()
        }else if(event.target.parentElement.dataset.click.match(/"forward"/)){
            app.forward()
        }else{
        
        }
    }else if(event.target.classList.contains('search-btn')){
        console.log(DEBUG_ALL,'search for:'+tbtop.get('search-input').value);//document.getElementById('search-input').value)
    }else if(event.target.classList.contains('nav-child-check')){
        return navChildren(event.target,true)
    }else if(event.target.classList.contains('nav-children')){
        return navChildren(event.target, false)
    }else if(event.target.classList.contains('x')){
        let pchild=event.target.parentElement.parentElement.parentElement.parentElement
        let spans=query(':scope>ul>li',pchild)
        if(spans.length>1){
            event.target.parentElement.parentElement.remove() 
        }else{
            query(':scope>ul',pchild).remove()
            //put a parent count on the span 
            app.addTerminatingHasMoreParents(query('span',pchild)[0])
        }

    }else if(event.target.classList.contains('nav-parents')){
        console.log(DEBUG_ALL,'nav-parents')
        let li=event.target.parentElement.parentElement
        let firstUl
        firstUl=li.querySelector('ul')
        if(firstUl){
            firstUl.remove()
        }else{
            //let maxDepth=parseInt(document.getElementById('max-depth').value)
            //let maxDepth=1//app.maxDepth
            let parentOfli=li.parentElement.parentElement  //would be an li
            //let indexOfChild
            //if(parseInt(li.dataset.depth)>=maxDepth){
            //?if(parseInt(li.firstElementChild.item.depth)>=maxDepth){
            //?    let ppul=parentOfli.parentElement
            //?    indexOfChild=Array.prototype.indexOf.call(parentOfli.parentElement.children, parentOfli);
            //?    parentOfli.parentElement.children[indexOfChild].dataset.depth=parseInt(parentOfli.parentElement.children[indexOfChild].dataset.depth)-2
            //?}
            let indexOfChild=Array.prototype.indexOf.call(li.parentElement.children, li);
            let depth=event.target.parentElement.item.depth
            li.remove()
            app.getItem_recursive(parentOfli, li.firstElementChild.item.uid,indexOfChild,depth ,depth)
            //app.showTree(parentOfli, li.firstElementChild.item.uid,indexOfChild ,1)
            //app.show(parentOfli, [li.firstElementChild.item.uid],indexOfChild)
        }
            
    }else if(event.target.classList.contains("url")){
        window.open(event.target.dataset.url, '_blank');
    //}else if(event.target.classList.contains("home-btn")){
    }else if(event.target.classList.contains("fa-people-group")){
    }else{
        //console.log(DEBUG_ALL,`not handled in document.addEventListener("click",event=>{`)
    }

})//end document.addEventListener("click",event=>{