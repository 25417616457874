import { Dexie, liveQuery } from "dexie";
import dexieCloud from 'dexie-cloud-addon'
import { getTiedRealmId } from 'dexie-cloud-addon'

app.load19e87d05=async(item, update)=>{ 
    //if('notFound' in item)delete item.notFound
    if(app.dexieDB.cloud.currentUserId=='unauthorized'){
        //Should we?? app.persons=undefined
        return item
    }else{
        console.log('fetching load19e87d05')
        let filePath
        filePath=app.useLocal?'http://localhost:1234/rlm-public-all.json':'rlm-public-all.json';
        return await fetch(filePath)
        .then(r=>r.json())
            .then(async j=>{
                if(update){
                    let getIt=j.data['rlm-public'].persons.find(f=>f.uid==item.uid)
                    if(getIt){
                        let index=app.persons.findIndex(f=>f.uid==item.uid)
                        if(index>-1){
                            getIt.realmId='server-json'
                            app.persons[index]=getIt
                        }else{
                            return{}
                        }
                    }
                }else{
                    app.persons=j.data['rlm-public'].persons
                    app.persons.map(m=>(m.realmId='server-json',m))
                    console.log(`got ${app.persons.length}`)
                //await db.bulkPut(persons)
                }
                return app.persons.filter(f=>f.uid==item.uid)[0]
            })
    }
}
//app.persons.filter(r=>r?.Label&&r.Label.match(/^g[\d]{0,3}\s/gim)&&!r?.AncestorOf)

document.addEventListener("DOMContentLoaded", function() {
    //console.log(DEBUG_ALL,'DOMContentLoaded')
    //app.buildTopToolbar()

    if (typeof Dexie === 'function'){
        let dexieDB = new Dexie('treeflex',{addons: [dexieCloud]});
        dexieDB.version(1).stores({
                persons:`@id, uid, Born, Died, sex, Cob, Info`,
                //persons: `@id, uid, Parents, Born, Died, sex, Cob, Info`,
                settings:`@id, [realmId+key]`,
                profile: `@id`,
                // Access Control
                realms: `@realmId`,
                members: `@id`,
                roles: `[realmId+name]`,
        });
        dexieDB.cloud.configure({
            databaseUrl: "https://zgaz4599o.dexie.cloud",
            requireAuth: false //false// true // optional
        });

        app.dexieDB=dexieDB
        //app.dexieCloud=dexieCloud
        //window.userId=dexieDB.cloud.currentUserId
        window.db=dexieDB.persons
        window.dbp=dexieDB.persons
        window.dbs=dexieDB.settings
        window.dbm=dexieDB.members
        window.dbr=dexieDB.realms
        window.getTiedRealmId=getTiedRealmId
        
        //watch user changes
    dexieDB.cloud.currentUser.asObservable().subscribe({
        next:change=>{
            console.log(DEBUG_ALL,`current user change: ${change.claims.sub}`)//+JSON.stringify(change))
            let loggedInUser=document.getElementById('logged-in-user')
            if(loggedInUser)loggedInUser.value=change.claims.sub
            ;['colourKey','check','unlock','shareWith','maxDepth','scale'].forEach(setting=>getSetting(setting,change.claims.sub))
            app.load19e87d05({uid:'19e87d05-6534-4167-8cb4-86f467a1e4f2'});
            if(!app.firstStart){
                delete app.firstStart
            }
        },
        error:error => console.log(DEBUG_ALL,error),
        complete:complete=>{
            console.log(DEBUG_ALL,'complete:'+JSON.stringify(complete))
            let showUser=document.getElementById('logged-in-user')
            if(showUser)showUser.value=dexieDB.cloud.currentUserId
        }
    })

    
    const settingsObservable = liveQuery(() => db.toArray());   
    const subscription = settingsObservable.subscribe({
        next: result => {
            if(!result.length)return
            console.log(DEBUG_ALL,"Got result:", result.length)//JSON.stringify(result))
            app.treeHasChanged=result.length
            //let treeView=document.getElementById('triggerit')
            //if(!treeView){
            let maxDepth, uid
                if(maxDepth=app.getAllUrlParams(location.href).maxdepth)app.maxDepth=parseInt(maxDepth);
                if(uid=app.getAllUrlParams(location.href).uid)app.treeRoot=[uid];
                if(!app.firstStart){
                    app.firstStart=true;app.replaceTree()
                }else{
                    //update visible elements??
                }
            //}
        }})
        

}})//end DOMContentLoaded

const getSetting=(setting, sub)=>{//colourKey; check; unlock; shareWith; email; scale
    dbs.where('[realmId+key]').equals([sub,setting]).toArray(all=>{
        all.forEach((x,i)=>i?dbs.delete(x.id):null)//delete all but the first just general house-keeping
        if(all?.length==1){
            if(setting=='maxDepth'&&app.getAllUrlParams(location.href)?.maxdepth){
            }else{
                dbs[setting]=all[0].id
                app[setting]=all[0].value
            }
            //if(['unlock'].indexOf(setting)>-1)app.buildLeftToolbar()//fix to know if grid or tree
            if(setting=='unlock')app.buildLeftToolbar()//fix to know if grid or tree
            //if((['maxDepth'].indexOf(setting)>-1)&&(!app.getAllUrlParams(location.href)?.maxdepth)){
            else if(setting=='maxDepth')
                w2ui['layout'].get('top').toolbar.set('depth-label', { count: app.maxDepth?app.maxDepth:'All'})
        }else{
                dbs[setting]=undefined  
        }
    }).finally(f=>{
        let treeDiv=document.getElementById('treediv');
        if(treeDiv){
            if(app?.colourKey){
                treeDiv.classList.remove('cob')
                treeDiv.classList.add(app.colourKey)
            }else{
                treeDiv.classList.remove(app.colourKey)
            }
        }
    })

};app.getSetting=getSetting



//**** https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript    ***
app.generateUUID=()=>{ // Public Domain/MIT
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

window.createNewItem=(relatesTo)=>{
    if(!relatesTo)relatesTo={}
    let uid=app.generateUUID(),
    Name='New Name',
    Label='New Label',
    sex=undefined,
    type=undefined,
    actions=null,
    Parents=[],
    Children=[],
    Spouses=[],
    Others=[]

    for (const [key, value] of Object.entries(relatesTo)){
        switch(key){
            case 'uid':uid=value;break;
            case 'Name':Name=value;break;
            case 'Label':Label=value;break;
            case 'sex':sex=value;break;
            case 'type':type=value;break;//'nav-infrastructure'

            case 'Parents':Parents=[...Parents,...value];break;
            case 'Children':Children=[...Children,...value];break;
            case 'Spouses':Spouses=[...Spouses,...value];break;
            case 'Others':Others=[...Others,...value];break;

            case 'parentOf':  //as in createNewItem({parentOf:'id-of-child'})
                Children=[Children,...value]
                break;

            case 'childOf':  //as in createNewItem({childOf:'id-of-parent'})
                Parents=[Parents,...value]
                break;
        }
    }
    let o={uid}
    if(Name)o.Name=Name;
    if(Label)o.Label=Label;
    if(sex)o.sex=sex;
    if(type)o.type=type;
    if(Parents)o.Parents=Parents;
    if(Children)o.Children=Children;
    if(Spouses)o.Spouses=Spouses;
    if(Others)o.Others=Others;
    if(actions)o.actions=actions;
    return o
}
/* ************************************ Sharing ********************************************************************************* */
//sharePersons(await db.where('id').equals('prs0P4bsO29cXKKOm2VrOvwdP3Gcal').first(),[{email:"user2@demo.local",name:"demo2"}])
window.sharePersons=sharePersons
export function sharePersons(item, ...shareWithPersons) {
    return app.dexieDB.transaction("rw", [db, dexieDB.realms, dexieDB.members], () => {//db:dexieDB.persons
      // Add or update a realm, tied to the todo-list using getTiedRealmId():
      const realmId = getTiedRealmId(item.id);
      dexieDB.realms.put({
        realmId,
        //name: item.Name,
        item:item,
        represents: "shared item",
      });
  
      // Move item into the realm (if not already there):
      db.update(item.id, { realmId });
  
      // Add the members to share it to:
      dexieDB.members.bulkAdd(
        shareWithPersons.map((person) => ({
          realmId,
          email: person.email,
          name: person.name,
          invite: false, //true, // Generates invite email on server on sync
          permissions: {
            manage: "*", // Give your person full permissions within this new realm.
          },
        }))
      );
    });
  }
  
  /** Unshare persons for given shareWithPersons
   *
   * @param {Object} item
   * @param {Array.<{name: string, email: string}>} ...shareWithPersons
   * @returns {Promise}
   */
window.unsharepersons=unsharepersons
  function unsharepersons(item, ...shareWithPersons) {
    return dexieDB.members
      .where("[realmId+email]")
      .anyOf(shareWithPersons.map((person) => [item.realmId, person.email]))
      .delete();
  }
/*sharing*/  

export default function dexieDB(){return dexieDB}